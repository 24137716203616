import React from "react";

import { Box, chakra, useBreakpointValue } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import ListHeader from "../ListHeader";

/**
 * @description Renders List item with children inside
 * @param {object} props
 * @param {JSX.Element} props.children Children to render inside List item
 * @returns {JSX.Element}
 */
const CustomListType = ({ children }) => {
    return (
        <chakra.li
            listStyleType={"none"}
            position={"relative"}
            px={"2.5rem"}
            paddingBottom="3.5rem"
            display={"block"}
            width={"100%"}
            lineHeight={"6"}
        >
            {children}
        </chakra.li>
    );
};

/**
 * @description Renders steps list
 * @returns {JSX.Element}
 */
const StepsList = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Box {...rest}>
            <chakra.ul
                marginLeft={"1.5rem"}
                justifyContent={"center"}
                display={useBreakpointValue({ md: "flex", base: "block" })}
                borderLeftWidth={useBreakpointValue({
                    base: `3px`,
                    md: "none",
                })}
                borderLeftStyle={"solid"}
                borderLeftColor={"white"}
                __css={useBreakpointValue({
                    md: {
                        counterReset: "step",
                        // Circle with number
                        "li:before": {
                            width: "4rem",
                            height: "4rem",
                            content: "counter(step)",
                            counterIncrement: "step",
                            lineHeight: "3.8rem",
                            fontSize: "2.5em",
                            fontWeight: "bold",
                            border: "5px solid white",
                            display: "block",
                            textAlign: "center",
                            margin: "0 auto 10px 0",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            color: "#1C85E8",
                            position: "relative",
                            zIndex: "2",
                            marginBottom: "2rem",
                        },
                        // Line after list
                        "li:after": {
                            width: "100%",
                            height: "2px",
                            content: "''",
                            position: "absolute",
                            backgroundColor: "white",
                            top: "1.8rem",
                            left: "3rem",
                            marginLeft: "20px",
                            backgroundClip: "content-box",
                        },
                        "li:last-child:after": {
                            content: "none",
                        },
                    },
                    // Numbers in vertical
                    base: {
                        counterReset: "step",
                        "li:before": {
                            content: "counter(step)",
                            fontSize: "2.5em",
                            fontWeight: "bold",
                            counterIncrement: "step",
                            textAlign: "center",
                            lineHeight: "4rem",
                            width: "4rem",
                            height: "4rem",
                            background: "#fff",
                            border: "2px solid white",
                            borderRadius: "50%",
                            position: "absolute",
                            top: "0.3rem",
                            color: "#1C85E8",
                            left: "-2.1rem",
                        },
                    },
                })}
            >
                <CustomListType>
                    <ListHeader fontSize={"xl"}>
                        {intl.formatMessage({
                            defaultMessage: "How to start?",
                        })}
                    </ListHeader>
                    {intl.formatMessage({
                        defaultMessage: `Select your preferred currency from the drop-down menu and input 
                                        all the necessary details required to calculate RoI either through 
                                        sliders or enter it manually. Then get going! Based on the inputs 
                                        that you have given, you get potential RoI.`,
                    })}
                </CustomListType>
                <CustomListType>
                    <ListHeader fontSize={"xl"}>
                        {intl.formatMessage({
                            defaultMessage: "Analyse your business processes",
                        })}
                    </ListHeader>
                    {intl.formatMessage({
                        defaultMessage: `A clear picture needs to be drawn about the apps involved, how many 
                                        times a process repeats, processes that are time-critical and prone 
                                        to mistakes, processes that include many disintegration systems using 
                                        the same data, and many more.`,
                    })}
                </CustomListType>
                <CustomListType>
                    <ListHeader fontSize={"xl"}>
                        {intl.formatMessage({
                            defaultMessage: "Get in touch",
                        })}
                    </ListHeader>
                    {intl.formatMessage({
                        defaultMessage: `Once you have seen the value of automation, click the button
                                        below to talk to the experts who will then advise on more
                                        detailed savings calculations, subject to more variables and
                                        factors tailored to your organisations’ unique IT
                                        infrastructure needs. You will be assigned a process
                                        automation expert who can assist you with your objectives.`,
                    })}
                </CustomListType>
            </chakra.ul>
        </Box>
    );
};

export default StepsList;
