// eslint-disable-next-line no-unused-vars
import { IntlShape } from "gatsby-plugin-react-intl";

/**
 * @description Get formatted currency value
 * @param {IntlShape} intlInstance
 * @param {number} value
 * @param {sting} currency
 * @returns {string}
 */
export const currencyFormat = (intlInstance, value, currency) => {
    return intlInstance.formatNumber(value, {
        style: "currency",
        currency,
        maximumFractionDigits: 0,
    });
};
