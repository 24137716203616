import React from "react";

import { Box, Container, Stack, Heading, Text } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import { SIZES } from "../../constants";
import ItemWithCheck from "../ItemWithCheck";

/**
 * @description Renders a LookFor section
 * @returns {JSX.Element}
 */
const LookFor = ({ ...rest }) => {
    const intl = useIntl();
    return (
        <Box backgroundColor={"gray.50"} {...rest}>
            <Container
                as={Stack}
                maxW={SIZES.containerMaxWidth}
                p={"10"}
                py={"20"}
            >
                <Stack direction={{ base: "column", md: "row" }} spacing={"20"}>
                    <Stack
                        direction={"column"}
                        width={{ base: "100%", md: "55%" }}
                        spacing={"6"}
                        borderRight={{ md: "1px" }}
                        borderColor={{ md: "gray.300" }}
                        paddingRight={{ md: "16" }}
                    >
                        <Heading>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Locating the best processes for calculating RoI",
                            })}
                        </Heading>
                        <Text>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Prioritising the tasks that need to be automated include",
                            })}
                        </Text>
                        <Stack spacing={"2"}>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Rule-based tasks like exporting data to a CRM,",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Labour-intensive tasks,",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Tasks with readable data, and ",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Tasks with structured data",
                                    })}
                                </Text>
                            </ItemWithCheck>
                        </Stack>
                        <Text>
                            {intl.formatMessage({
                                defaultMessage: `All these tasks are time-consuming and repetitive in nature. 
                                                In such tasks, human errors are common and can have a domino 
                                                effect that can throw off analytics and even deliverables.`,
                            })}
                        </Text>
                    </Stack>
                    <Stack
                        direction={"column"}
                        width={{ base: "100%", md: "45%" }}
                        spacing={"6"}
                    >
                        <Heading>
                            {intl.formatMessage({
                                defaultMessage:
                                    "Key business areas of process automation:",
                            })}
                        </Heading>
                        <Stack spacing={"2"}>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage: "Banking and Finance",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Customer service management",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage: "Document processing",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage: "Invoice management",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage: "HR management",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Insurance claim processing, and so on.",
                                    })}
                                </Text>
                            </ItemWithCheck>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default LookFor;
