import React from "react";

import { Stack } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Features from "../components/features/Features";
import Hero from "../components/hero/Hero";
import Steps from "../components/steps/Steps";
import SEO from "../components/SEO/SEO";
import Calculator from "../components/calculator/Calculator";
import Contact from "../components/contact";
import LookFor from "../components/lookfor";
import Benefits from "../components/benefits";

const IndexPage = () => {
    const intl = useIntl();

    return (
        <main>
            <Stack spacing={"24"}>
                <SEO
                    title={intl.formatMessage({
                        defaultMessage: "Automation RoI calculator",
                    })}
                />
                <Hero />
                <Contact />
                <Calculator />
                <LookFor />
                <Benefits />
                <Steps />
                <Features />
            </Stack>
        </main>
    );
};

export default IndexPage;
