import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const YearlyCost = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 46"
            aria-labelledby={"yearlyCostTitle"}
            {...rest}
        >
            <title id={"yearlyCostTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Business process automation",
                })}
            </title>
            <desc id={"yearlyCostDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing stack of coins.",
                })}
            </desc>
            <g data-name="[icon]-yearly-cost" transform="translate(.187 .292)">
                <g data-name="Group 19677">
                    <rect
                        width="46"
                        height="46"
                        fill="#ffe2cf"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 -.292)"
                    ></rect>
                </g>
                <g transform="translate(12.838 13.618)">
                    <g data-name="Group 19660" transform="translate(9.44 2.95)">
                        <g data-name="Group 19659">
                            <path
                                fill="#d69062"
                                d="M245.35 107.77c-1.541 0-5.35-.216-5.35-1.77v2.36c0 1.687 4.464 1.77 5.35 1.77s5.349-.083 5.349-1.77V106c.001 1.554-3.808 1.77-5.349 1.77z"
                                data-name="Path 27013"
                                transform="translate(-240 -106)"
                            ></path>
                        </g>
                    </g>
                    <g data-name="Group 19662" transform="translate(9.44)">
                        <g data-name="Group 19661">
                            <path
                                fill="#d69062"
                                d="M245.35 31c-.887 0-5.35.085-5.35 1.77s4.463 1.77 5.35 1.77 5.349-.085 5.349-1.77S246.236 31 245.35 31z"
                                data-name="Path 27014"
                                transform="translate(-240 -31)"
                            ></path>
                        </g>
                    </g>
                    <g data-name="Group 19664" transform="translate(9.44 6.49)">
                        <g data-name="Group 19663">
                            <path
                                fill="#d69062"
                                d="M245.35 197.77c-1.541 0-5.35-.216-5.35-1.77v.9a6.511 6.511 0 012.008 2.912 16.916 16.916 0 003.341.32c.885 0 5.349-.083 5.349-1.77V196c.002 1.554-3.807 1.77-5.348 1.77z"
                                data-name="Path 27015"
                                transform="translate(-240 -196)"
                            ></path>
                        </g>
                    </g>
                    <g
                        data-name="Group 19666"
                        transform="translate(11.622 10.03)"
                    >
                        <g data-name="Group 19665">
                            <path
                                fill="#d69062"
                                d="M298.625 287.77a17.662 17.662 0 01-3.051-.257 6.112 6.112 0 01-.117 2.334 17.438 17.438 0 003.168.283c.885 0 5.349-.083 5.349-1.77V286c.001 1.554-3.808 1.77-5.349 1.77z"
                                data-name="Path 27016"
                                transform="translate(-295.457 -286)"
                            ></path>
                        </g>
                    </g>
                    <g
                        data-name="Group 19668"
                        transform="translate(10.025 13.57)"
                    >
                        <g data-name="Group 19667">
                            <path
                                fill="#d69062"
                                d="M259.633 377.77a16.217 16.217 0 01-3.529-.367 6.5 6.5 0 01-1.236 1.863c1.229.81 4.078.864 4.765.864.885 0 5.349-.083 5.349-1.77V376c0 1.554-3.808 1.77-5.349 1.77z"
                                data-name="Path 27017"
                                transform="translate(-254.868 -376)"
                            ></path>
                        </g>
                    </g>
                    <g data-name="Group 19670" transform="translate(0 7.08)">
                        <g data-name="Group 19669">
                            <path
                                fill="#d69062"
                                d="M5.31 211a5.31 5.31 0 105.31 5.31A5.316 5.316 0 005.31 211zm.606 8.154H5.9v.694a.59.59 0 01-1.18 0v-.7a2.427 2.427 0 01-1.079-.76.59.59 0 01.9-.758.909.909 0 00.967.411.59.59 0 00-.2-1.145 1.772 1.772 0 01-1.77-1.77 1.727 1.727 0 011.18-1.655v-.7a.59.59 0 111.18 0v.706a2.278 2.278 0 01.872.523.59.59 0 01-.822.847.808.808 0 00-.867-.262.59.59 0 00.229 1.134 1.77 1.77 0 01.606 3.434z"
                                data-name="Path 27018"
                                transform="translate(0 -211)"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default YearlyCost;
