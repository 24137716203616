import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const ImprovedBusiness = () => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="5em"
            height="5em"
            viewBox="0 0 81 81"
            aria-labelledby={"improvedBusinessTitle"}
        >
            <title id={"improvedBusinessTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Job automation calculator",
                })}
            </title>
            <desc id={"improvedBusinessDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing graph going up.",
                })}
            </desc>
            <g
                data-name="[icon]-improved-business"
                transform="translate(-580 -4472)"
            >
                <rect
                    width="81"
                    height="81"
                    fill="#dbebf9"
                    data-name="Rectangle 3454"
                    rx="25"
                    transform="translate(580 4472)"
                ></rect>
                <path
                    fill="#1277d6"
                    d="M34.875 27H4.5V5.625A1.125 1.125 0 003.375 4.5h-2.25A1.125 1.125 0 000 5.625V29.25a2.25 2.25 0 002.25 2.25h32.625A1.125 1.125 0 0036 30.375v-2.25A1.125 1.125 0 0034.875 27zm-2.25-20.25h-8.3a1.687 1.687 0 00-1.193 2.881l2.278 2.278-5.159 5.16-5.16-5.159a2.249 2.249 0 00-3.182 0l-4.83 4.83a1.125 1.125 0 000 1.591l1.59 1.59a1.125 1.125 0 001.591 0l3.24-3.239 5.159 5.159a2.249 2.249 0 003.182 0l6.75-6.75 2.278 2.278a1.687 1.687 0 002.881-1.193v-8.3a1.124 1.124 0 00-1.125-1.126z"
                    data-name="Icon awesome-chart-line"
                    transform="translate(602.5 4494.5)"
                ></path>
            </g>
        </Icon>
    );
};

export default ImprovedBusiness;
