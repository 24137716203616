import React from "react";

import { Stack, Box, Heading } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Card from "../Card";
import { currencyFormat } from "../../utilities";

/**
 * @description Renders a single overview slot
 * @param {Object} props
 * @param {JSX.Element} props.top
 * @param {JSX.Element} props.value
 * @param {JSX.Element} props.bottom
 * @returns {JSX.Element}
 */
const OverviewSlot = ({ top, value, bottom, ...rest }) => {
    return (
        <Card
            width={"84%"}
            background={"linear-gradient(#00008a,#020255)"}
            {...rest}
        >
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                height={"24"}
                spacing={"0"}
            >
                <Box color={"gray.300"} fontSize={"xs"}>
                    {top}
                </Box>
                <Box fontSize={"2xl"} fontWeight={"bold"}>
                    {value}
                </Box>
                <Box color={"gray.300"} fontSize={"xs"}>
                    {bottom}
                </Box>
            </Stack>
        </Card>
    );
};

/**
 * @description Renders overview card
 * @param {object} props
 * @param {{totalTasksPerYear: number,totalSavingsPerYear: number, totalTimeSavedPerYear: number, increaseRevenuePerYear: number}} props.calculated
 * @param {string} props.currency
 * @returns {JSX.Element}
 */
const Overview = ({ calculated, currency, ...rest }) => {
    const intl = useIntl();

    return (
        <Card
            color={"white"}
            backgroundColor={"overviewBg"}
            py={"10"}
            {...rest}
        >
            <Stack alignItems={"center"} spacing={"4"}>
                <Heading color={"gray.200"} fontSize={"3xl"} pb={"2"}>
                    {intl.formatMessage({
                        defaultMessage: "Overview",
                    })}
                </Heading>
                <Heading
                    as={"h3"}
                    color={"gray.200"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                >
                    {intl.formatMessage({
                        defaultMessage: "Savings with automation",
                    })}
                </Heading>
                <OverviewSlot
                    top={intl.formatMessage({
                        defaultMessage: "Tasks Automated",
                    })}
                    value={calculated.totalTasksPerYear}
                    bottom={intl.formatMessage({
                        defaultMessage: "per year",
                    })}
                />
                <OverviewSlot
                    top={intl.formatMessage({
                        defaultMessage: "Money Saved",
                    })}
                    value={currencyFormat(
                        intl,
                        calculated.totalSavingsPerYear,
                        currency
                    )}
                    bottom={intl.formatMessage({
                        defaultMessage: "per year",
                    })}
                />
                <OverviewSlot
                    top={intl.formatMessage({
                        defaultMessage: "Time Saved",
                    })}
                    value={intl.formatNumber(calculated.totalTimeSavedPerYear)}
                    bottom={intl.formatMessage({
                        defaultMessage: "hours per year",
                    })}
                />
                <OverviewSlot
                    top={intl.formatMessage({
                        defaultMessage: "Increased Revenue",
                    })}
                    value={currencyFormat(
                        intl,
                        calculated.increaseRevenuePerYear,
                        currency
                    )}
                    bottom={intl.formatMessage({
                        defaultMessage: "per year",
                    })}
                />
            </Stack>
        </Card>
    );
};

export default Overview;
