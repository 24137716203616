import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const ClientInvolvement = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 46"
            aria-labelledby={"clientInvolvementTitle"}
            {...rest}
        >
            <title id={"clientInvolvementTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Process automation calculator",
                })}
            </title>
            <desc id={"clientInvolvementDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing watch.",
                })}
            </desc>
            <g
                data-name="[icon]-client-involvement"
                transform="translate(.187 .242)"
            >
                <g data-name="Group 19677">
                    <rect
                        width="46"
                        height="46"
                        fill="#ebf5ff"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 -.242)"
                    ></rect>
                </g>
                <g transform="translate(12.618 12.259)">
                    <path
                        fill="#6db8ff"
                        d="M115.368 101.22a10.251 10.251 0 1010.254 10.251 10.264 10.264 0 00-10.254-10.251zm3.148 13.279l-4.035-2.329v-5.593h1.774v4.569l3.148 1.817z"
                        data-name="Path 36"
                        transform="translate(-105.114 -101.22)"
                    ></path>
                </g>
            </g>
        </Icon>
    );
};

export default ClientInvolvement;
