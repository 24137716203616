export const PLANS = Object.freeze({
    STARTER: "starter",
    BUSINESS: "business",
    BUSINESS_PREMIUM: "business_premium",
    ENTERPRISE: "enterprise",
    CUSTOM: "custom",
});

export const PLANS_NAMES = Object.freeze({
    [PLANS.STARTER]: "Starter",
    [PLANS.BUSINESS]: "Business",
    [PLANS.BUSINESS_PREMIUM]: "Business Premium",
    [PLANS.ENTERPRISE]: "Enterprise",
    [PLANS.CUSTOM]: "Custom",
});

export const CURRENCIES = Object.freeze({
    USD: "USD",
    DKK: "DKK",
    EUR: "EUR",
});

export const PLANS_LIGHTHOUSE_PRICE = Object.freeze({
    [CURRENCIES.DKK]: {
        [PLANS.STARTER]: 149,
        [PLANS.BUSINESS]: 249,
        [PLANS.BUSINESS_PREMIUM]: 399,
        [PLANS.ENTERPRISE]: 699,
        [PLANS.CUSTOM]: 1499,
    },
    [CURRENCIES.USD]: {
        [PLANS.STARTER]: 24,
        [PLANS.BUSINESS]: 40,
        [PLANS.BUSINESS_PREMIUM]: 64,
        [PLANS.ENTERPRISE]: 119,
        [PLANS.CUSTOM]: 249,
    },
    [CURRENCIES.EUR]: {
        [PLANS.STARTER]: 20,
        [PLANS.BUSINESS]: 34,
        [PLANS.BUSINESS_PREMIUM]: 54,
        [PLANS.ENTERPRISE]: 93,
        [PLANS.CUSTOM]: 200,
    },
});

export const PROCESSES = Object.freeze({
    INVOICE_AUTOMATION: "invoice_automation",
    LEAD_AUTOMATION: "lead_automation",
    MARKETING_AUTOMATION: "marketing_automation",
    SALES_AUTOMATION: "sales_automation",
    SUPPLY_CHAIN_AUTOMATION: "supply_chain_automation",
    ACCOUNTING_AUTOMATION: "accounting_automation",
    OTHER: "other",
});

export const PROCESSES_NAMES = Object.freeze({
    [PROCESSES.INVOICE_AUTOMATION]: "Invoice Automation",
    [PROCESSES.LEAD_AUTOMATION]: "Lead Automation",
    [PROCESSES.MARKETING_AUTOMATION]: "Marketing Automation",
    [PROCESSES.SALES_AUTOMATION]: "Sales Automation",
    [PROCESSES.SUPPLY_CHAIN_AUTOMATION]: "Supply Chain Automation",
    [PROCESSES.ACCOUNTING_AUTOMATION]: "Accounting Automation",
    [PROCESSES.OTHER]: "Other",
});

export const APPS = Object.freeze({
    PIPEDRIVE: "pipedrive",
    ECONOMIC: "economic",
    MAILCHIMP: "mailchimp",
    HUBSPOT: "hubspot",
    SHOPIFY: "shopify",
    MAGENTO: "magento",
    WOOCOMMERCE: "woocommerce",
    MS_BUSINESS_CENTRAL: "ms_business_central",
    OTHER: "other",
});

export const APPS_NAMES = Object.freeze({
    [APPS.PIPEDRIVE]: "Pipedrive",
    [APPS.ECONOMIC]: "e-conomic",
    [APPS.MAILCHIMP]: "Mailchimp",
    [APPS.HUBSPOT]: "HubSpot",
    [APPS.SHOPIFY]: "Shopify",
    [APPS.MAGENTO]: "Magento",
    [APPS.WOOCOMMERCE]: "WooCommerce",
    [APPS.MS_BUSINESS_CENTRAL]: "MS Business Central",
    [APPS.OTHER]: "Other",
});

/**
 * @description Dials data for all the dials for implementation
 * @param {string} currency
 * @returns
 */
export const ImplementationDials = (intl) => {
    return {
        implementationCost: {
            title: intl.formatMessage({
                defaultMessage: "Implementation Cost",
            }),
            value: {
                max: 300000,
                unit: "{{currency}}",
                default: 0,
                current: 0,
            },
        },
        implementationTime: {
            title: intl.formatMessage({
                defaultMessage: "Implementation time",
            }),
            value: {
                max: 52,
                unit: intl.formatMessage({
                    defaultMessage: "Weeks",
                }),
                default: 0,
                current: 0,
            },
        },
        clientInvolvement: {
            title: intl.formatMessage({
                defaultMessage: "Client involvement",
            }),
            value: {
                max: 100,
                unit: intl.formatMessage({
                    defaultMessage: "Hrs",
                }),
                default: 0,
                current: 0,
            },
        },
    };
};

/**
 * @description Dials data for all the dials of a process
 * @param {string} currency
 * @returns
 */
export const ProcessDials = (intl) => {
    return {
        tasksPerMonth: {
            title: intl.formatMessage({
                defaultMessage: "Tasks per month",
            }),
            value: {
                max: 10000,
                unit: intl.formatMessage({
                    defaultMessage: "Tasks",
                }),
                default: 100,
                current: 100,
            },
        },
        durationPerTask: {
            title: intl.formatMessage({
                defaultMessage: "Duration per task",
            }),
            value: {
                max: 120,
                unit: intl.formatMessage({
                    defaultMessage: "Mins",
                }),
                default: 20,
                current: 20,
            },
        },
        costOfLabour: {
            title: intl.formatMessage({
                defaultMessage: "Cost of labour",
            }),
            value: {
                max: 500,
                unit: "{{currency}}",
                default: 110,
                current: 110,
            },
        },
    };
};

/**
 * @description Dials data for all the dials of a running section
 * @param {string} currency
 * @returns
 */
export const RunningCostDials = (intl) => {
    return {
        app: {
            title: intl.formatMessage({
                defaultMessage: "App",
            }),
            value: { default: 0, max: 1000, unit: "{{currency}}", current: 0 },
        },
        revenue: {
            title: intl.formatMessage({
                defaultMessage: "Revenue",
            }),
            value: {
                default: 0,
                max: 1000000,
                unit: "{{currency}}",
                current: 0,
            },
        },
    };
};
