import React, { useState } from "react";

import {
    Button,
    Box,
    Flex,
    Heading,
    Spacer,
    Divider,
    Stack,
} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { useIntl } from "gatsby-plugin-react-intl";

import Process from "./Process";
import AddDialog from "./AddDialog";
import Card from "../Card";
import { PROCESSES, PROCESSES_NAMES } from "./constants";

/**
 * @description Add and renders all the processes
 * @param {Object} props
 * @param {string} props.currency
 * @param {Object} props.processes
 * @param {boolean} props.addAllowed
 * @param {function} props.onAdd
 * @param {function} props.onRemove
 * @param {function} props.onChange
 * @returns {JSX.Element}
 */
const Processes = ({
    currency,
    processes,
    addAllowed,
    onAdd,
    onRemove,
    onChange,
    ...rest
}) => {
    const [isAddProcessDialogOpen, setIsAddProcessDialogOpen] = useState(false);
    const intl = useIntl();

    return (
        <Card px={"8"} py={"8"} {...rest}>
            <Flex
                direction={{ base: "column", md: "row" }}
                mb={"8"}
                alignItems={{ md: "center" }}
            >
                <Stack direction={"column"}>
                    <Heading fontSize={"2xl"}>
                        {intl.formatMessage({
                            defaultMessage: "Process",
                        })}
                    </Heading>
                    <Heading as={"h3"} fontSize={"md"} fontWeight={"normal"}>
                        {intl.formatMessage({
                            defaultMessage:
                                "Calculate estimated implementation cost & time",
                        })}
                    </Heading>
                </Stack>
                <Spacer />
                <Button
                    mt={{ base: "4", md: "0" }}
                    leftIcon={<AiOutlinePlus />}
                    colorScheme={"blue"}
                    onClick={() => {
                        if (addAllowed)
                            setIsAddProcessDialogOpen(!isAddProcessDialogOpen);
                        else onAdd({});
                    }}
                    variant={"outline"}
                    fontSize={"sm"}
                >
                    {intl.formatMessage({
                        defaultMessage: "Add Process",
                    })}
                </Button>
            </Flex>
            <Divider mb={"8"} />
            <AddDialog
                onClose={() => setIsAddProcessDialogOpen(false)}
                isOpen={isAddProcessDialogOpen}
                onAddAction={({ type, name }) => {
                    setIsAddProcessDialogOpen(false);
                    onAdd({ type, name });
                }}
                options={PROCESSES}
                optionsNames={PROCESSES_NAMES}
                title={intl.formatMessage({ defaultMessage: "Add a process" })}
                placeholderText={intl.formatMessage({
                    defaultMessage: "Enter process name",
                })}
                actionText={intl.formatMessage({
                    defaultMessage: "Add process",
                })}
            />

            {Object.keys(processes).map((processKey, index, array) => (
                <Box key={processKey}>
                    {index > 0 ? <Divider mb={"8"} /> : null}
                    <Process
                        dials={processes[processKey].dials}
                        name={processes[processKey].name}
                        savingsPerYear={processes[processKey].savingsPerYear}
                        currency={currency}
                        onRemove={() => onRemove({ key: processKey })}
                        onChange={(dialKey, value) =>
                            onChange({ key: processKey, dialKey, value })
                        }
                        pb={index < array.length - 1 ? "4" : ""}
                    />
                </Box>
            ))}
        </Card>
    );
};

export default Processes;
