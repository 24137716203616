import localforge from "localforage";
import { STORAGE_KEYS } from "../constants";

/**
 * @description Save contact info to local storage
 * @param {Object} values
 * @param {string} values.name
 * @param {string} values.email
 * @param {string} values.company
 * @returns {Promise<{name:string, email: string, company: string}>}
 */
export const saveContactInfo = async ({ name, email, company }) => {
    return await localforge.setItem(STORAGE_KEYS.contactInfo, {
        name,
        email,
        company,
    });
};

/**
 * @description Get contact info local storage
 * @returns {Promise<{name:string, email: string, company: string}>}
 */
export const getContactInfo = async () => {
    return await localforge.getItem(STORAGE_KEYS.contactInfo);
};
