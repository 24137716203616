import React from "react";

// eslint-disable-next-line no-unused-vars
import { Box, ChakraProps } from "@chakra-ui/react";

/**
 * @description Renders a card like component
 * @param {ChakraProps} props
 * @returns {JSX.Element}
 */
const Card = ({ children, ...rest }) => {
    return (
        <Box
            boxShadow={"0 3px 10px rgb(0 0 0 / 0.2)"}
            rounded={"3xl"}
            {...rest}
        >
            {children}
        </Box>
    );
};

export default Card;
