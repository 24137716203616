import React from "react";

import { Stack, Box, Spacer, Divider } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Card from "../Card";

import ClientInvolvement from "./icons/ClientInvolvement";
import ImplementationTime from "./icons/ImplementationTime";
import MonthlyCost from "./icons/MonthlyCost";
import PaybackOnInvestment from "./icons/PaybackOnInvestment";
import UpfrontInvestment from "./icons/UpfrontInvestment";
import YearlyCost from "./icons/YearlyCost";

import { currencyFormat } from "../../utilities";

/**
 * @description Renders a single row for payback component
 * @param {Object} props
 * @param {JSX.Element} props.icon
 * @param {string} props.title
 * @param {number} props.value
 * @param {string} props.unit
 * @returns {JSX.Element}
 */
const PaybackRow = ({ icon, title, value, unit }) => {
    return (
        <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Box>{icon}</Box>
            <Box color={"gray.600"} fontWeight={"normal"} fontSize={"sm"}>
                {title}
            </Box>
            <Spacer />
            <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={"0.5"}
            >
                <Box fontWeight={"bold"} fontSize={"lg"}>
                    {value}
                </Box>
                <Box color={"gray.600"} fontWeight={"light"} fontSize={"xs"}>
                    {unit}
                </Box>
            </Stack>
        </Stack>
    );
};

/**
 * @description Renders payback card
 * @param {object} props
 * @param {Object} props.values
 * @param {string} props.currency
 * @returns {JSX.Element}
 */
const Payback = ({ values, currency, ...rest }) => {
    const intl = useIntl();
    const iconSize = { height: "10", width: "10" };

    return (
        <Card {...rest}>
            <Stack px={"8"} py={"10"} spacing={"4"}>
                <PaybackRow
                    icon={<PaybackOnInvestment {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Payback on investment",
                    })}
                    value={intl.formatNumber(values.paybackInMonths)}
                    unit={intl.formatMessage(
                        {
                            defaultMessage:
                                "{count, plural, one {month} other {months}}",
                        },
                        { count: values.paybackInMonths }
                    )}
                />
                <PaybackRow
                    icon={<ImplementationTime {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Implementation time",
                    })}
                    value={intl.formatNumber(
                        values.implementationTime.value.current
                    )}
                    unit={intl.formatMessage(
                        {
                            defaultMessage:
                                "{count, plural, one {week} other {weeks}}",
                        },
                        { count: values.implementationTime.value.current }
                    )}
                />
                <PaybackRow
                    icon={<ClientInvolvement {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Client involvement",
                    })}
                    value={intl.formatNumber(
                        values.clientInvolvement.value.current
                    )}
                    unit={intl.formatMessage(
                        {
                            defaultMessage:
                                "{count, plural, one {hour} other {hours}}",
                        },
                        { count: values.clientInvolvement.value.current }
                    )}
                />
                <Divider />
                <PaybackRow
                    icon={<UpfrontInvestment {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Upfront investment",
                    })}
                    value={currencyFormat(
                        intl,
                        values.implementationCost.value.current,
                        currency
                    )}
                />
                <PaybackRow
                    icon={<MonthlyCost {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Monthly running cost",
                    })}
                    value={currencyFormat(
                        intl,
                        values.totalRunningCost,
                        currency
                    )}
                />
                <PaybackRow
                    icon={<YearlyCost {...iconSize} />}
                    title={intl.formatMessage({
                        defaultMessage: "Cost 1. year",
                    })}
                    value={currencyFormat(
                        intl,
                        values.totalRunningCostPerYear,
                        currency
                    )}
                />
            </Stack>
        </Card>
    );
};

export default Payback;
