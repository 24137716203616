import React from "react";

import { Icon } from "@chakra-ui/react";

/**
 * @description Renders a curved svg
 * @returns {JSX.Element}
 */
const Curve = ({ ...rest }) => {
    return (
        <Icon
            width="100%"
            height="100%"
            maxH={"24"}
            preserveAspectRatio="none"
            viewBox="0 0 500 54"
            fill={"header"}
            {...rest}
        >
            <path d="M 0 0 L 0 5 Q 21 15 50 24 Q 248 83 450 24 Q 479 15 500 6 L 500 0 Z"></path>
        </Icon>
    );
};

export default Curve;
