import React from "react";

import {
    Box,
    Heading,
    Stack,
    InputGroup,
    Input,
    InputRightAddon,
    Tooltip,
} from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import { PLANS_NAMES } from "./constants";

/**
 * @description Renders a dial for lighthouse
 * @param {object} props
 * @param {{unit: string, current: number}} props.value
 * @param {string} bestPlan
 * @returns {JSX.Element}
 */
const LighthouseDial = ({ value, bestPlan, ...rest }) => {
    const intl = useIntl();

    return (
        <Box {...rest}>
            <Stack spacing={"6"} direction={"row"} alignItems={"center"}>
                <Box flexGrow={"2"}>
                    <Heading as={"h4"} fontWeight={"normal"} fontSize={"lg"}>
                        Cloudify Lighthouse
                    </Heading>
                </Box>
                <Tooltip
                    label={
                        intl.formatMessage({
                            defaultMessage:
                                "Calculated based on TPM. Current Plan:",
                        }) + ` ${PLANS_NAMES[bestPlan]}`
                    }
                >
                    <InputGroup size="sm" flexGrow={"1"} maxWidth={"32"}>
                        <Input
                            disabled
                            value={value.current}
                            _disabled={{
                                color: "black",
                                cursor: "not-allowed",
                            }}
                        />
                        <InputRightAddon children={value.unit} />
                    </InputGroup>
                </Tooltip>
            </Stack>
        </Box>
    );
};

export default LighthouseDial;
