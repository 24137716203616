import React from "react";

import { Stack, Icon } from "@chakra-ui/react";
import { AiOutlineCheckCircle } from "react-icons/ai";

/**
 * @description Renders child with a checkbox.
 * @param {object} props
 * @param {JSX.Element} props.children Element to include with check
 * @returns {JSX.Element}
 */
const ItemWithCheck = ({ children }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={"7"}>
            <Icon
                height={"7"}
                width={"7"}
                color={"yellow.400"}
                as={AiOutlineCheckCircle}
            />
            {children}
        </Stack>
    );
};

export default ItemWithCheck;
