import React from "react";

import {
    Box,
    Container,
    Stack,
    Heading,
    Text,
    Icon,
    SimpleGrid,
} from "@chakra-ui/react";
import {
    FaThumbsUp,
    FaChartLine,
    FaChartPie,
    FaCheckCircle,
} from "react-icons/fa";
import { useIntl } from "gatsby-plugin-react-intl";

import { SIZES } from "../../constants";
import Card from "../Card";

/**
 * @description Renders a single benefit card
 * @param {import("@chakra-ui/system").ChakraProps} props
 * @param {import("react-icons/lib").IconType} props.icon
 * @param {string} props.heading
 * @param {string} props.paragraph
 * @return {JSX.Element}
 */
const BenefitCard = ({ icon, heading, paragraph, ...rest }) => {
    return (
        <Card p={"10"} backgroundColor={"benefitCard"} {...rest}>
            <Stack spacing={"8"}>
                <Icon as={icon} color={"blue.500"} w={8} h={8} />
                <Stack>
                    <Heading as={"h3"} fontSize={"2xl"}>
                        {heading}
                    </Heading>
                    <Text>{paragraph}</Text>
                </Stack>
            </Stack>
        </Card>
    );
};

/**
 * @description Renders Benefits section.
 * @returns {JSX.Element}
 */
const Benefits = ({ ...rest }) => {
    const intl = useIntl();
    return (
        <Box {...rest}>
            <Container
                as={Stack}
                maxW={SIZES.containerMaxWidth}
                p={"10"}
                pb={"20"}
            >
                <Stack
                    direction={{ base: "column", md: "row" }}
                    spacing={{ base: "10", md: "20" }}
                >
                    <Heading width={{ md: "80%" }}>
                        {intl.formatMessage({
                            defaultMessage:
                                "Knowing the benefits of automation",
                        })}
                    </Heading>
                    <SimpleGrid
                        columns={{ base: "1", md: "2" }}
                        spacingX={16}
                        spacingY={10}
                    >
                        <BenefitCard
                            icon={FaChartLine}
                            heading={intl.formatMessage({
                                defaultMessage: "Positive RoI",
                            })}
                            paragraph={intl.formatMessage({
                                defaultMessage: `Your company will be able to reduce the operational 
                                                cost and provide positive ROI from the first day of 
                                                implementation.`,
                            })}
                        />
                        <BenefitCard
                            icon={FaThumbsUp}
                            heading={intl.formatMessage({
                                defaultMessage:
                                    "Enhanced customer satisfaction",
                            })}
                            paragraph={intl.formatMessage({
                                defaultMessage: `Automation helps you create more personalised experience 
                                                for your customers.`,
                            })}
                        />
                        <BenefitCard
                            icon={FaChartPie}
                            heading={intl.formatMessage({
                                defaultMessage: "Improved process quality",
                            })}
                            paragraph={intl.formatMessage({
                                defaultMessage: `Process automation provides more visibility for process 
                                                improvement.`,
                            })}
                        />
                        <BenefitCard
                            icon={FaCheckCircle}
                            heading={intl.formatMessage({
                                defaultMessage: "Improved business agility",
                            })}
                            paragraph={intl.formatMessage({
                                defaultMessage: `To see impactful results faster, the businesses can deploy process 
                                                automation quickly, thus making incremental changes.`,
                            })}
                        />
                    </SimpleGrid>
                </Stack>
            </Container>
        </Box>
    );
};

export default Benefits;
