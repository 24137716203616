import { useRef, useEffect } from "react";

/**
 * @description Scroll to referred element.
 * @param {string} id
 * @returns
 */
const useSmoothScrollTo = (id) => {
    const ref = useRef(null);
    useEffect(() => {
        const listener = (e) => {
            if (ref.current && location.hash === id) {
                const yOffset = -68;
                const y =
                    ref.current.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
            }
        };
        window.addEventListener("hashchange", listener, true);
        return () => {
            window.removeEventListener("hashchange", listener);
        };
    }, []);
    return {
        "data-anchor-id": id,
        ref,
    };
};

export default useSmoothScrollTo;
