import React from "react";

import { Heading, Stack, Text, Container, Box } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import MainIllustration from "./MainIllustration";
import Curve from "./Curve";
import ItemWithCheck from "../ItemWithCheck";
import { SIZES } from "../../constants";

/**
 * @description Renders our hero section
 * @returns {JSX.Element}
 */
const Hero = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Box {...rest}>
            <Box backgroundColor={"header"} color={"white"}>
                <Container
                    as={Stack}
                    maxW={SIZES.containerMaxWidth}
                    p={"10"}
                    pt={"20"}
                    direction={{ base: "column", md: "row" }}
                    align={"center"}
                    justifyContent={"center"}
                    spacing={"16"}
                >
                    <Stack spacing={6} w={"full"} flexGrow={"2"}>
                        <Heading as={"h1"}>
                            <Text
                                as={"span"}
                                fontSize={{
                                    base: "4xl",
                                    md: "3xl",
                                    lg: "5xl",
                                }}
                                fontWeight={"bold"}
                            >
                                {intl.formatMessage({
                                    defaultMessage:
                                        "Your Process Automation Calculator",
                                })}
                            </Text>
                        </Heading>
                        <Text as={"h2"} fontSize={{ base: "md", lg: "lg" }}>
                            {intl.formatMessage({
                                defaultMessage: `The Process Automation Calculator by Cloudify helps you
                                                predict your potential savings by letting you know
                                                about the yearly effects of the business process
                                                automation. This calculator helps the client
                                                determine the suitable package based on the`,
                            })}
                        </Text>
                        <Stack spacing={4} fontSize={{ base: "md", lg: "lg" }}>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Tasks completed per month",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage:
                                            "Work duration per task",
                                    })}
                                </Text>
                            </ItemWithCheck>
                            <ItemWithCheck>
                                <Text>
                                    {intl.formatMessage({
                                        defaultMessage: "Labour cost involved",
                                    })}
                                </Text>
                            </ItemWithCheck>
                        </Stack>
                    </Stack>

                    <Box flexGrow={"1"} width="100%">
                        <MainIllustration />
                    </Box>
                </Container>
            </Box>
            <Curve position="relative" top="-2px" />
        </Box>
    );
};

export default Hero;
