import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const ManualHoursSaved = () => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="5em"
            height="5em"
            viewBox="0 0 81 81"
            aria-labelledby={"manualHoursSavedTitle"}
        >
            <title id={"manualHoursSavedTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Job automation calculator",
                })}
            </title>
            <desc id={"manualHoursSavedDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing hour glass.",
                })}
            </desc>
            <g
                fill="#1277d6"
                data-name="[icon]-manual-hours-saved"
                transform="translate(-580 -4250)"
            >
                <rect
                    width="81"
                    height="81"
                    data-name="Rectangle 3451"
                    opacity="0.15"
                    rx="25"
                    transform="translate(580 4250)"
                ></rect>
                <path
                    d="M29.222 30.516h-.359c-.457 0-.844-.063-.893-.52v-.007C26.944 20.433 20.925 20.25 20.925 18s6.026-2.433 7.045-11.988V6c.049-.457.436-.52.893-.52h.359a1.008 1.008 0 001.013-.935.986.986 0 00-.984-1.034H6.778a1.008 1.008 0 00-1.012.935.986.986 0 00.984 1.038h.387c.457 0 .844.063.893.52v.007C9.056 15.567 15.075 15.75 15.075 18S9.049 20.433 8.03 29.988V30c-.049.457-.436.52-.893.52h-.359a1.008 1.008 0 00-1.013.935.986.986 0 00.984 1.034h22.5a.982.982 0 00.984-1.034 1.008 1.008 0 00-1.011-.939zm-17.74-2.4c2.018-5.611 5.6-4.929 5.6-7.144v-3.914c0-1.364-2.587-2.313-4.2-4.528a.55.55 0 01.457-.872h9.345a.548.548 0 01.471.858c-1.589 2.222-4.226 3.178-4.226 4.542v3.909c0 2.194 3.438 1.6 5.611 7.144.2.5.049 1.125-.485 1.125H11.967c-.541 0-.667-.619-.485-1.125z"
                    data-name="Icon ionic-ios-hourglass"
                    transform="translate(602.5 4272.5)"
                ></path>
            </g>
        </Icon>
    );
};

export default ManualHoursSaved;
