import React from "react";

import { Container, Heading, Stack, Box, Text } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import StepsList from "./StepsList";
import { SIZES } from "../../constants";
import useSmoothScrollTo from "../../hooks/useSmoothScrollTo";

/**
 * @description Renders our steps section
 * @returns {JSX.Element}
 */
const Steps = ({ ...rest }) => {
    const intl = useIntl();
    const scrollBind = useSmoothScrollTo("#steps");

    return (
        <Box
            background={"linear-gradient(#1C85E8,#0E4374)"}
            color={"white"}
            {...scrollBind}
            {...rest}
        >
            <Container
                as={Stack}
                maxW={SIZES.containerMaxWidth}
                py={"10"}
                pt={"24"}
                align={"center"}
                spacing={"10"}
            >
                <Heading>
                    {intl.formatMessage({
                        defaultMessage: "How does it work?",
                    })}
                </Heading>

                <Text
                    as={"h2"}
                    width="50%"
                    textAlign="center"
                    fontSize={"md"}
                    fontWeight={"normal"}
                >
                    {intl.formatMessage({
                        defaultMessage: `The process automation calculator is a no-code workflow
                                        automation RoI calculator that can be used by businesses
                                        working across multiple disciplines. It’s quite simple!`,
                    })}
                </Text>

                <Box width="100%">
                    <StepsList />
                </Box>
            </Container>
        </Box>
    );
};

export default Steps;
