import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const ImplementationTime = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 45"
            aria-labelledby={"implementationTimeTitle"}
            {...rest}
        >
            <title id={"implementationTimeTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Process automation calculator",
                })}
            </title>
            <desc id={"implementationTimeDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing stopwatch.",
                })}
            </desc>
            <g
                data-name="[icon]-implementation-time"
                transform="translate(.187 -.018)"
            >
                <g data-name="Group 19677">
                    <rect
                        width="46"
                        height="45"
                        fill="#eafdf7"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 .018)"
                    ></rect>
                </g>
                <g
                    fill="#3ae0a5"
                    data-name="09-timer"
                    transform="translate(11.778 9.261)"
                >
                    <path
                        d="M26.912 7.824l1.574-1.574-1.549-1.55-1.683 1.684a10.882 10.882 0 00-5.208-1.949V2.19h2.19V0h-6.57v2.19h2.19v2.245a10.882 10.882 0 00-5.208 1.949L10.964 4.7 9.416 6.25l1.574 1.574a10.951 10.951 0 1015.923 0zm-7.961 16.268a8.761 8.761 0 118.761-8.761 8.761 8.761 0 01-8.761 8.761z"
                        data-name="Path 33"
                        transform="translate(-8)"
                    ></path>
                    <path
                        d="M30.571 32v6.571H24A6.571 6.571 0 1030.571 32z"
                        data-name="Path 34"
                        transform="translate(-19.62 -23.239)"
                    ></path>
                </g>
            </g>
        </Icon>
    );
};

export default ImplementationTime;
