import React from "react";

import {
    Box,
    Heading,
    Stack,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    InputGroup,
    NumberInput,
    NumberInputField,
    InputRightAddon,
    Flex,
    Spacer,
    CloseButton,
} from "@chakra-ui/react";

/**
 * @description Renders a slider with current value
 * @param {object} props
 * @param {string} props.title
 * @param {{max: number, unit: string, default: number, current: number}} props.value
 * @param {string} props.currency
 * @param {function} props.onChange
 * @param {function} props.onRemove
 * @returns {JSX.Element}
 */
const Dial = ({ title, value, currency, onChange, onRemove, ...rest }) => {
    return (
        <Box {...rest}>
            <Flex alignItems={"center"} pb={"4"}>
                <Heading as={"h4"} fontWeight={"normal"} fontSize={"md"}>
                    {title}
                </Heading>
                <Spacer />
                {onRemove && <CloseButton onClick={onRemove} />}
            </Flex>
            <Stack spacing={"6"} direction={"row"}>
                <Slider
                    focusThumbOnChange={false}
                    aria-label={title}
                    defaultValue={value.default}
                    max={value.max}
                    flexGrow={"2"}
                    onChange={(value) => {
                        if (typeof onChange === "function") onChange(value);
                    }}
                    value={value.current}
                >
                    <SliderTrack>
                        <SliderFilledTrack bg={"blue.300"} />
                    </SliderTrack>
                    <SliderThumb boxSize={6} />
                </Slider>
                <InputGroup size="sm" flexGrow={"1"} maxWidth={"32"}>
                    <NumberInput
                        allowMouseWheel
                        defaultValue={value.current}
                        max={value.max}
                        min={0}
                        onChange={(valueAsNumber) => {
                            if (typeof onChange === "function")
                                onChange(valueAsNumber);
                        }}
                        value={value.current}
                    >
                        <NumberInputField paddingInlineEnd={"3"} />
                    </NumberInput>
                    <NumberInput />
                    <InputRightAddon
                        children={
                            value.unit === "{{currency}}"
                                ? currency
                                : value.unit
                        }
                    />
                </InputGroup>
            </Stack>
        </Box>
    );
};

export default Dial;
