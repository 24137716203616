import React from "react";

import {
    SimpleGrid,
    Stack,
    Text,
    Box,
    useBreakpointValue,
} from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import ImprovedBusiness from "./icons/ImprovedBusiness";
import ImprovedRegulatory from "./icons/ImprovedRegulatory";
import IncreasedCustomer from "./icons/IncreasedCustomer";
import ManualHoursSaved from "./icons/ManualHoursSaved";
import ProductivityGain from "./icons/ProductivityGain";
import QualityImprovement from "./icons/QualityImprovement";

/**
 * @description Renders stacked icon and label.
 * @param {object} props
 * @param {JSX.Element} props.icon Icon to render.
 * @param {string} props.label Label for the icon.
 * @returns {JSX.Element}
 */
const IconWithLabel = ({ icon, label, ...rest }) => {
    return (
        <Stack
            justifyItems="center"
            width="fit-content"
            maxWidth={"56"}
            alignItems="center"
            {...rest}
        >
            <Box> {icon} </Box>
            <Box pt={"4"}>
                <Text textAlign={"center"} fontWeight={"bold"}>
                    {label}
                </Text>
            </Box>
        </Stack>
    );
};

/**
 * @description Renders grid of features.
 * @returns {JSX.Element}
 */
const FeaturesGrid = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <SimpleGrid
            columns={useBreakpointValue({ md: "3", base: "1" })}
            spacing={useBreakpointValue({ md: "20", base: "15" })}
            spacingY={"12"}
            justifyItems="center"
            {...rest}
        >
            <IconWithLabel
                icon={<ManualHoursSaved />}
                label={intl.formatMessage({
                    defaultMessage: "Manual hours saved",
                })}
            />
            <IconWithLabel
                icon={<QualityImprovement />}
                label={intl.formatMessage({
                    defaultMessage:
                        "Quality improvement through error reduction",
                })}
            />
            <IconWithLabel
                icon={<ProductivityGain />}
                label={intl.formatMessage({
                    defaultMessage: "Productivity gain",
                })}
            />
            <Box />
            <IconWithLabel
                icon={<ImprovedBusiness />}
                label={intl.formatMessage({
                    defaultMessage: "Improved business agility",
                })}
            />
            <Box />
        </SimpleGrid>
    );
};

export default FeaturesGrid;
