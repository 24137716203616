import React, { useState, useMemo } from "react";

import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Container,
    Stack,
    Select,
    FormControl,
    FormLabel,
    Flex,
    Box,
} from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Processes from "./Processes";
import Implementation from "./Implementation";
import Running from "./Running";
import Overview from "./Overview";
import Payback from "./Payback";
import {
    process as processHelper,
    implementation as implementationHelper,
    running as runningHelper,
} from "./calculatorHelper";
import { SIZES } from "../../constants";
import { CURRENCIES, ImplementationDials, RunningCostDials } from "./constants";
import { calculateOverview } from "./utilities";

const tabSelectedStyle = {
    borderBottom: `6px solid black;`,
    borderBottomColor: "blue.600",
    fontWeight: "bold",
};
const tabStyle = {
    _selected: tabSelectedStyle,
    color: "blue.600",
    fontSize: { base: "sm", md: "xl" },
};

/**
 * @description Renders calculator section
 * @param {Object} props
 * @returns {JSX.Element}
 */
const Calculator = ({ ...rest }) => {
    const intl = useIntl();

    const [currentCurrency, setCurrentCurrency] = useState(CURRENCIES.USD);
    const [data, setData] = useState({
        processes: {},
        implementation: { dials: ImplementationDials(intl) },
        running: {
            apps: {},
            revenue: { value: RunningCostDials(intl).revenue.value },
        },
    });

    const calculated = calculateOverview({ ...data, currentCurrency });

    return (
        <Box {...rest}>
            <Container as={Stack} maxW={SIZES.containerMaxWidth}>
                <Stack
                    direction={{ base: "column", md: "row" }}
                    width={"100%"}
                    spacing={"10"}
                >
                    <Box width={"100%"}>
                        <Tabs flexGrow={"2"} variant={"unstyled"}>
                            <TabList>
                                <Tab {...tabStyle}>
                                    {intl.formatMessage({
                                        defaultMessage: "Savings",
                                    })}
                                </Tab>
                                <Tab {...tabStyle}>
                                    {intl.formatMessage({
                                        defaultMessage: "Investments",
                                    })}
                                </Tab>

                                <Flex width={"100%"} justifyContent={"end"}>
                                    <FormControl
                                        as={Flex}
                                        display={{ base: "none", md: "flex" }}
                                        direction={"row"}
                                        alignItems={"center"}
                                        width={{ base: "32", md: "48" }}
                                        pt={"2"}
                                        pl={"1"}
                                    >
                                        <FormLabel
                                            fontSize={{
                                                base: "sm",
                                                md: "md",
                                            }}
                                            mb={"0"}
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: "Currency",
                                            })}
                                        </FormLabel>

                                        <Select
                                            onChange={(event) => {
                                                setCurrentCurrency(
                                                    event.target.value
                                                );
                                            }}
                                        >
                                            {Object.values(CURRENCIES).map(
                                                (currency) => (
                                                    <option
                                                        key={currency}
                                                        value={currency}
                                                    >
                                                        {currency}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Flex>
                            </TabList>

                            <TabPanels>
                                <TabPanel px={"0"}>
                                    <Processes
                                        addAllowed={true}
                                        currency={currentCurrency}
                                        onChange={({ key, dialKey, value }) =>
                                            setData(
                                                processHelper.change({
                                                    data,
                                                    key,
                                                    dialKey,
                                                    value,
                                                    currentCurrency,
                                                })
                                            )
                                        }
                                        onAdd={({ type, name }) => {
                                            setData(
                                                processHelper.add({
                                                    data,
                                                    type,
                                                    name,
                                                    currentCurrency,
                                                    intl,
                                                })
                                            );
                                        }}
                                        onRemove={({ key }) =>
                                            setData(
                                                processHelper.remove({
                                                    data,
                                                    key,
                                                })
                                            )
                                        }
                                        processes={data.processes}
                                    />
                                </TabPanel>
                                <TabPanel px={"0"}>
                                    <Stack spacing={"4"}>
                                        <Implementation
                                            currency={currentCurrency}
                                            onChange={({ dialKey, value }) =>
                                                setData(
                                                    implementationHelper.change(
                                                        {
                                                            data,
                                                            dialKey,
                                                            value,
                                                        }
                                                    )
                                                )
                                            }
                                            dials={data.implementation.dials}
                                        />
                                        <Running
                                            addAllowed={true}
                                            currency={currentCurrency}
                                            onAppChange={({ key, value }) =>
                                                setData(
                                                    runningHelper.appChange({
                                                        data,
                                                        key,
                                                        value,
                                                        lighthouseCost:
                                                            calculated.lighthouseCost,
                                                    })
                                                )
                                            }
                                            onAddApp={({ type, name }) => {
                                                setData(
                                                    runningHelper.addApp({
                                                        data,
                                                        type,
                                                        name,
                                                        lighthouseCost:
                                                            calculated.lighthouseCost,
                                                        intl,
                                                    })
                                                );
                                            }}
                                            onRemoveApp={({ key }) => {
                                                setData(
                                                    runningHelper.removeApp({
                                                        data,
                                                        key,
                                                        lighthouseCost:
                                                            calculated.lighthouseCost,
                                                    })
                                                );
                                            }}
                                            onRevenueChange={({ value }) => {
                                                setData(
                                                    runningHelper.revenueChange(
                                                        {
                                                            data,
                                                            value,
                                                        }
                                                    )
                                                );
                                            }}
                                            running={data.running}
                                            lighthouseCost={
                                                calculated.lighthouseCost
                                            }
                                            bestPlan={calculated.bestPlan}
                                            totalRunningCost={
                                                calculated.totalRunningCost
                                            }
                                        />
                                    </Stack>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                    <Stack
                        pt={{ base: "0", md: "14" }}
                        spacing={"8"}
                        width={{ base: "100%", md: "2xl" }}
                    >
                        <Overview
                            calculated={calculated}
                            currency={currentCurrency}
                        />
                        <Payback
                            values={{
                                ...calculated,
                                ...data?.implementation.dials,
                                ...data?.running,
                            }}
                            currency={currentCurrency}
                        />
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default Calculator;
