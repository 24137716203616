import { nanoid } from "nanoid";
// eslint-disable-next-line no-unused-vars
import { IntlShape } from "gatsby-plugin-react-intl";

import { ProcessDials, RunningCostDials } from "./constants";
import { calculateSavingsFromDials } from "./utilities";

export const process = {
    /**
     * @description Returns new data with new process inserted.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.currentCurrency
     * @param {string} props.type
     * @param {string} props.name
     * @param {IntlShape} props.intl
     * @returns
     */
    add: ({ data, currentCurrency, type, name, intl }) => {
        const newDials = ProcessDials(intl);
        const newProcesses = {
            ...data.processes,
            [nanoid()]: {
                type,
                name,
                dials: newDials,
                ...calculateSavingsFromDials(newDials, currentCurrency),
            },
        };
        return { ...data, processes: newProcesses };
    },
    /**
     * @description Returns new data with changes made in process.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.currentCurrency
     * @param {string} props.key
     * @param {string} props.dialKey
     * @param {number} props.value
     * @returns
     */
    change: ({ data, currentCurrency, key, dialKey, value }) => {
        const processes = data.processes;
        const newDials = {
            ...processes[key].dials,
            [dialKey]: {
                ...processes[key].dials[dialKey],
                value: {
                    ...processes[key].dials[dialKey].value,
                    current: value,
                },
            },
        };
        const newProcesses = {
            ...processes,
            [key]: {
                ...processes[key],
                dials: newDials,
                ...calculateSavingsFromDials(newDials, currentCurrency),
            },
        };

        return { ...data, processes: newProcesses };
    },
    /**
     * @description Returns new data with process with given key removed.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.key
     * @returns
     */
    remove: ({ data, key }) => {
        delete data.processes[key];
        return { ...data };
    },
};

export const implementation = {
    /**
     * @description Returns new data with implementation details changed.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.dialKey
     * @param {number} props.value
     * @returns
     */
    change: ({ data, dialKey, value }) => {
        const implementation = data.implementation;
        const newDials = {
            ...implementation.dials,
            [dialKey]: {
                ...implementation.dials[dialKey],
                value: {
                    ...implementation.dials[dialKey].value,
                    current: value,
                },
            },
        };
        const newImplementation = {
            ...implementation,
            dials: newDials,
        };
        return { ...data, implementation: newImplementation };
    },
};

export const running = {
    /**
     * @description Returns new data with new app added.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.type
     * @param {string} props.name
     * @param {IntlShape} props.intl
     * @returns
     */
    addApp: ({ data, type, name, intl }) => {
        const value = RunningCostDials(intl).app.value;
        const newApps = {
            ...data.running.apps,
            [nanoid()]: {
                type,
                name,
                value,
            },
        };
        const newRunning = {
            ...data.running,
            apps: newApps,
        };
        return { ...data, running: newRunning };
    },
    /**
     * @description Returns new data with app value changed.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.key
     * @param {number} props.value
     * @returns
     */
    appChange: ({ data, key, value }) => {
        const apps = data.running.apps;
        const newApps = {
            ...apps,
            [key]: {
                ...apps[key],
                value: {
                    ...apps[key].value,
                    current: value,
                },
            },
        };
        const newRunning = {
            ...data.running,
            apps: newApps,
        };
        return { ...data, running: newRunning };
    },
    /**
     * @description Returns new data with app with given key removed.
     * @param {Object} props
     * @param {Object} props.data
     * @param {string} props.key
     * @returns
     */
    removeApp: ({ data, key }) => {
        delete data.running.apps[key];
        const newRunning = {
            ...data.running,
        };
        return { ...data, running: newRunning };
    },

    /**
     * @description Returns new data with revenue data changed.
     * @param {Object} props
     * @param {Object} props.data
     * @param {number} props.value
     * @returns
     */
    revenueChange: ({ data, value }) => {
        const newRevenue = {
            ...data.running.revenue,
            value: { ...data.running.revenue.value, current: value },
        };
        const newRunning = {
            ...data.running,
            revenue: newRevenue,
        };
        return { ...data, running: newRunning };
    },
};
