import React from "react";

import { Stack, Heading as ChakraHeading, Divider } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Dial from "./Dial";
import Heading from "./Heading";
import Card from "../Card";

/**
 * @description Renders component to allow setting values for implementation
 * @param {Object} props
 * @param {Object} props.dials
 * @param {string} props.currency
 * @param {function} props.onChange
 * @returns {JSX.Element}
 */
const Implementation = ({ dials, currency, onChange, ...rest }) => {
    const intl = useIntl();
    return (
        <Card px={"8"} py={"8"} {...rest}>
            <Stack spacing={"4"}>
                <Stack direction={"column"} mb={"4"}>
                    <ChakraHeading fontSize={"2xl"}>
                        {intl.formatMessage({
                            defaultMessage: "Implementation cost & time",
                        })}
                    </ChakraHeading>
                    <ChakraHeading
                        as={"h4"}
                        fontSize={"md"}
                        fontWeight={"normal"}
                    >
                        {intl.formatMessage({
                            defaultMessage:
                                "Calculate estimated implementation cost & time",
                        })}
                    </ChakraHeading>
                </Stack>
                <Divider mb={"8"} />
                <Heading>
                    {intl.formatMessage({
                        defaultMessage: "Cost",
                    })}
                </Heading>
                <Dial
                    title={dials.implementationCost.title}
                    value={dials.implementationCost.value}
                    onChange={(value) =>
                        onChange({ dialKey: "implementationCost", value })
                    }
                    currency={currency}
                />
                <Heading>
                    {intl.formatMessage({
                        defaultMessage: "Time",
                    })}
                </Heading>
                <Dial
                    title={dials.implementationTime.title}
                    value={dials.implementationTime.value}
                    onChange={(value) =>
                        onChange({ dialKey: "implementationTime", value })
                    }
                    currency={currency}
                />
                <Dial
                    title={dials.clientInvolvement.title}
                    value={dials.clientInvolvement.value}
                    onChange={(value) =>
                        onChange({ dialKey: "clientInvolvement", value })
                    }
                    currency={currency}
                />
            </Stack>
        </Card>
    );
};

export default Implementation;
