import React from "react";

import { Box, Flex, Divider, Stack, Spacer } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import Heading from "./Heading";
import Dial from "./Dial";
import { currencyFormat } from "../../utilities";

/**
 * @description Renders a single process
 * @param {Object} props
 * @param {string} props.name
 * @param {Object} props.dials
 * @param {number} props.savingsPerYear
 * @param {string} props.currency
 * @param {function} props.onChange
 * @param {function} props.onRemove
 * @returns {JSX.Element}
 */
const Process = ({
    name,
    dials,
    savingsPerYear,
    currency,
    onChange,
    onRemove,
    ...rest
}) => {
    const intl = useIntl();

    return (
        <Stack spacing={"4"} {...rest}>
            <Heading onRemove={onRemove}>{name}</Heading>
            {Object.keys(dials).map((dialKey) => {
                const current = dials[dialKey];
                return (
                    <Dial
                        key={dialKey}
                        {...current}
                        onChange={(value) => onChange(dialKey, value)}
                        currency={currency}
                    />
                );
            })}
            <Divider pt={"4"} />
            <Flex alignItems={"center"}>
                <Box>
                    {intl.formatMessage({
                        defaultMessage: "Savings per year",
                    })}
                </Box>
                <Spacer />
                <Box fontWeight={"bold"} fontSize={"2xl"}>
                    {currencyFormat(intl, savingsPerYear, currency)}
                </Box>
            </Flex>
        </Stack>
    );
};

export default Process;
