import React from "react";

import { Container, Stack, Heading, Text, Box } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

import FeaturesGrid from "./FeaturesGrid";
import { SIZES } from "../../constants";
import useSmoothScrollTo from "../../hooks/useSmoothScrollTo";

/**
 * @description Renders Text with child inside.
 * @returns {JSX.Element}
 */
const FeatureParagraph = ({ children, ...rest }) => {
    return (
        <Text lineHeight={"6"} fontWeight={"light"} {...rest}>
            {children}
        </Text>
    );
};

/**
 * @description Renders our feature section
 * @returns {JSX.Element}
 */
const Features = ({ ...rest }) => {
    const intl = useIntl();
    const scrollBind = useSmoothScrollTo("#features");

    return (
        <Box {...scrollBind} {...rest}>
            <Container
                as={Stack}
                maxW={SIZES.featureContainerMaxWidth}
                align={"center"}
                spacing={"10"}
            >
                <Heading>
                    <Text>
                        {intl.formatMessage({
                            defaultMessage: "Automate. Every second counts…",
                        })}
                    </Text>
                </Heading>
                <FeatureParagraph>
                    {intl.formatMessage({
                        defaultMessage: `And at Cloudify, we help you identify and automate the manual and repetitive 
                                        tasks so you can reduce the manual efforts and minimise human errors. For example, 
                                        copying and pasting customer’s data into excel sheets, sending personalised emails, 
                                        getting real time shipping rates, updating your Mailchimp mailing list, sending thank 
                                        you message, and so on, are repetitive and takes a lot of your business hours.
`,
                    })}
                </FeatureParagraph>
                <FeatureParagraph>
                    {intl.formatMessage({
                        defaultMessage: `To identify the time lapse, Cloudify has introduced the Process Automation Calculator 
                                        to decipher the amount of time and money you can save on automation. The RoI analysis 
                                        is done by considering different categories of possible savings that include:
`,
                    })}
                </FeatureParagraph>
                <FeaturesGrid />
                <FeatureParagraph>
                    {intl.formatMessage({
                        defaultMessage: `The Process Automation Calculator shows a measurable impact on the top-line revenue 
                                        of your business along with primary cost reduction.`,
                    })}
                </FeatureParagraph>
                <FeatureParagraph>
                    {intl.formatMessage({
                        defaultMessage: `Based on the RoI analysis by our process automation calculator, we give an estimation 
                                        of your potential savings on the time and cost. We give you the freedom to customize 
                                        your automation by visiting Marketplace by Cloudify. 
`,
                    })}
                </FeatureParagraph>
            </Container>
        </Box>
    );
};

export default Features;
