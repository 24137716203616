import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const MonthlyCost = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 46"
            aria-labelledby={"monthlyCostTitle"}
            {...rest}
        >
            <title id={"monthlyCostTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Process automation calculator",
                })}
            </title>
            <desc id={"monthlyCostDesc"}>
                {intl.formatMessage({
                    defaultMessage:
                        "A graphic showing calculator with dollar sign on top.",
                })}
            </desc>
            <g data-name="[icon]-monthly-cost" transform="translate(.187 .066)">
                <g data-name="Group 19677">
                    <rect
                        width="46"
                        height="46"
                        fill="#cffaff"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 -.066)"
                    ></rect>
                </g>
                <g fill="#1ce1e8" transform="translate(11.778 11.627)">
                    <path
                        d="M5.31 0a5.31 5.31 0 105.31 5.31A5.316 5.316 0 005.31 0zm.59 8.152v.7a.59.59 0 11-1.18 0v-.7a1.765 1.765 0 01-.826-.593.59.59 0 11.944-.709.584.584 0 00.472.236.59.59 0 000-1.18 1.766 1.766 0 01-.59-3.431v-.7a.59.59 0 111.18 0v.7a1.765 1.765 0 01.826.593.59.59 0 11-.944.709.611.611 0 00-1.062.353.59.59 0 00.59.59 1.766 1.766 0 01.59 3.432z"
                        data-name="Path 27007"
                    ></path>
                    <g data-name="Group 19655" transform="translate(5.9 2.36)">
                        <path
                            d="M163.649 60h-8.477a6.577 6.577 0 01.692 2.36h5.347a.59.59 0 01.59.59v4.72a.59.59 0 01-.59.59h-8.085A6.3 6.3 0 01150 69.4v7.785a.59.59 0 00.59.59h13.059a.59.59 0 00.59-.59V60.59a.59.59 0 00-.59-.59zm-7.159 9.44h1.18a.59.59 0 010 1.18h-1.18a.59.59 0 110-1.18zm0 2.36h1.18a.59.59 0 010 1.18h-1.18a.59.59 0 110-1.18zm-2.36 3.54h-1.18a.59.59 0 110-1.18h1.18a.59.59 0 010 1.18zm0-2.36h-1.18a.59.59 0 110-1.18h1.18a.59.59 0 010 1.18zm0-2.36h-1.18a.59.59 0 110-1.18h1.18a.59.59 0 010 1.18zm7.08 4.72h-4.72a.59.59 0 110-1.18h4.72a.59.59 0 010 1.18zm0-2.36h-1.18a.59.59 0 110-1.18h1.18a.59.59 0 010 1.18zm0-2.36h-1.18a.59.59 0 110-1.18h1.18a.59.59 0 010 1.18z"
                            data-name="Path 27008"
                            transform="translate(-150 -60)"
                        ></path>
                        <path
                            d="M268.148 153.54V150h-4.77a6.455 6.455 0 01-1.448 3.54z"
                            data-name="Path 27009"
                            transform="translate(-257.527 -146.46)"
                        ></path>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default MonthlyCost;
