import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { useIntl } from "gatsby-plugin-react-intl";

import Facebook from "./Facebook";
import Twitter from "./Twitter";

const query = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl
                defaultImage: image
                facebook
                keywords
            }
        }
    }
`;

const SEO = ({ description, title, image }) => {
    const { site } = useStaticQuery(query);
    const { pathname } = useLocation();
    const intl = useIntl();
    const locale = intl.locale;

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        facebook,
        keywords,
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || ""}`,
    };

    return (
        <>
            <Helmet title={title} titleTemplate={titleTemplate}>
                <html lang={locale} />
                <meta name={"description"} content={seo.description} />
                <meta name={"image"} content={seo.image} />
                <meta name={"keywords"} content={keywords} />
                <meta
                    name="google-site-verification"
                    content="Ky46izS--9_OeNU3n6RMcSeZ2TqHC8NLIkXrvDuiE14"
                />
            </Helmet>
            <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type={"website"}
                url={seo.url}
                locale={locale}
                name={facebook}
            />
            <Twitter
                title={seo.title}
                image={seo.image}
                desc={seo.description}
            />
        </>
    );
};

SEO.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
};

SEO.defaultProps = {
    description: null,
    title: null,
    image: null,
};
export default SEO;
