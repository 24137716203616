import axios from "axios";

const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

import { HUBSPOT } from "../../constants";

/**
 * @description Submit the form to hubspot
 * @param {Object} values
 * @param {string} values.name
 * @param {string} values.email
 * @param {string} values.company
 * @param {string} values.pageName
 * @param {string} values.pageUri
 * @returns {Promise<void>}
 */
export const submitToHubspot = async ({
    name,
    email,
    company,
    pageName,
    pageUri,
}) => {
    const [firstname, ...restName] = name.split(" ");
    const lastname = restName.join(" ");
    const contactData = {
        submittedAt: Date.now().toString(),
        fields: [
            { name: "email", value: email },
            { name: "firstname", value: firstname },
            { name: "lastname", value: lastname },
            { name: "company", value: company },
        ],
        context: {
            pageUri,
            pageName,
        },
    };
    await axios.post(HUBSPOT.contactFormUrl, contactData);
};
