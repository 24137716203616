import { PLANS, PLANS_LIGHTHOUSE_PRICE } from "./constants";

/**
 * @description Select & return best plan
 * @param {number} tasks
 * @returns {string}
 */
export const getBestPlan = (tasks) => {
    if (tasks <= 250) {
        return PLANS.STARTER;
    } else if (tasks > 250 && tasks <= 500) {
        return PLANS.BUSINESS;
    } else if (tasks > 500 && tasks <= 1000) {
        return PLANS.BUSINESS_PREMIUM;
    } else if (tasks > 1000 && tasks <= 4000) {
        return PLANS.ENTERPRISE;
    } else {
        return PLANS.CUSTOM;
    }
};

/**
 * @description Calculate yearly savings for a process
 * @param {number} tasks
 * @param {number} durationPerTask
 * @param {number} labourCost
 * @param {string} currency
 * @returns
 */
export const calculateSavings = (
    tasksPerMonth,
    durationPerTask,
    labourCost,
    currency
) => {
    const timeSavedPerYear = (tasksPerMonth * durationPerTask * 12) / 60;
    const savingsPerYear = Math.round(
        timeSavedPerYear * labourCost -
            PLANS_LIGHTHOUSE_PRICE[currency][getBestPlan(tasksPerMonth)] * 12
    );
    return { timeSavedPerYear, savingsPerYear };
};

/**
 * @description Calculate running cost for apps
 * @param {number} lighthouseCost
 * @param {{string: {name: string, type: string, value: {current: number}}}} apps
 * @returns {number}
 */
export const calculateRunningCost = (lighthouseCost, apps) => {
    return (
        Object.values(apps).reduce((p, c) => p + c.value.current, 0) +
        lighthouseCost
    );
};

/**
 * @description Calculate values for overview
 * @param {object} values
 * @param {{string: {savingsPerYear: number, tasksPerMonth: number, timeSavedPerYear: number, dials:{tasksPerMonth:{value: {current: number}}}}}} values.processes
 * @param {{revenue: {value: {current:number}}, totalRunningCost: number, app: object}} values.running
 * @param {{dials: {implementationCost: {value: {current:number}}}}} values.implementation
 * @param {string} values.currentCurrency
 */
export const calculateOverview = ({
    processes,
    running,
    implementation,
    currentCurrency,
}) => {
    let totalTasksPerMonth = 0,
        addedSavingsPerYear = 0,
        addedTimeSavedPerYear = 0;
    for (const process of Object.values(processes ?? {})) {
        totalTasksPerMonth += parseInt(
            process?.dials.tasksPerMonth.value.current ?? 0
        );
        addedSavingsPerYear += parseInt(process?.savingsPerYear ?? 0);
        addedTimeSavedPerYear += parseInt(process?.timeSavedPerYear ?? 0);
    }
    const { cost: lighthouseCost, bestPlan } = getLighthouseCost(
        totalTasksPerMonth,
        currentCurrency
    );
    const totalRunningCost = calculateRunningCost(lighthouseCost, running.apps);
    const totalRunningCostPerYear =
        (totalRunningCost ?? 0) * 12 +
        parseInt(implementation.dials.implementationCost.value.current);
    const totalTasksPerYear = parseInt(totalTasksPerMonth) * 12;
    const totalSavingsPerYear = addedSavingsPerYear - totalRunningCostPerYear;
    const totalTimeSavedPerYear = addedTimeSavedPerYear;
    const increaseRevenuePerYear =
        parseInt(running?.revenue?.value.current ?? 0) * 12;
    let paybackInMonths = Math.round(
        (totalRunningCostPerYear /
            (totalSavingsPerYear + increaseRevenuePerYear)) *
            12
    );
    if (Number.isNaN(paybackInMonths)) paybackInMonths = 0;
    return {
        lighthouseCost,
        bestPlan,
        totalRunningCost,
        totalTasksPerMonth,
        totalTasksPerYear,
        totalSavingsPerYear,
        totalTimeSavedPerYear,
        increaseRevenuePerYear,
        paybackInMonths,
        totalRunningCostPerYear,
    };
};

/**
 * @description Calculate savings from dials values
 * @param {Object} dials
 * @param {string} currency
 * @returns
 */
export const calculateSavingsFromDials = (dials, currency) => {
    return calculateSavings(
        dials.tasksPerMonth.value.current,
        dials.durationPerTask.value.current,
        dials.costOfLabour.value.current,
        currency
    );
};

/**
 * @description Returns lighthouse plan cost selecting best plan.
 * @param {number} totalTasksPerMonth Total number of tasks.
 * @param {string} currentCurrency Plan for which currency.
 * @returns {{bestPlan: string, cost: number}}
 */
export const getLighthouseCost = (totalTasksPerMonth, currentCurrency) => {
    const bestPlan = getBestPlan(totalTasksPerMonth ?? 0);
    return {
        bestPlan,
        cost: PLANS_LIGHTHOUSE_PRICE[currentCurrency][bestPlan],
    };
};
