import React, { useState } from "react";

import {
    Button,
    Box,
    Flex,
    Spacer,
    Heading,
    Stack,
    Divider,
} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { useIntl } from "gatsby-plugin-react-intl";

import AddDialog from "./AddDialog";
import Dial from "./Dial";
import LighthouseDial from "./LigthouseDial";
import Card from "../Card";
import { APPS, APPS_NAMES } from "./constants";
import { currencyFormat } from "../../utilities";

/**
 * @description Renders a component to allow adding apps
 * @param {Object} props
 * @param {Object} props.apps
 * @param {string} props.currency
 * @param {number} props.totalRunningCost
 * @param {number} props.lighthouseCost
 * @param {string} props.bestPlan
 * @param {boolean} props.addAllowed
 * @param {function} props.onAddApp
 * @param {function} props.onRemoveApp
 * @param {function} props.onAppChange
 * @param {function} props.onRevenueChange
 * @returns {JSX.Element}
 */
const Running = ({
    running,
    currency,
    totalRunningCost,
    lighthouseCost,
    bestPlan,
    addAllowed,
    onAddApp,
    onRemoveApp,
    onAppChange,
    onRevenueChange,
    ...rest
}) => {
    const [isAddAppDialogOpen, setIsAddAppDialogOpen] = useState(false);
    const intl = useIntl();

    return (
        <Card px={"8"} py={"8"} {...rest}>
            <Stack spacing={"4"}>
                <Flex
                    direction={{ base: "column", md: "row" }}
                    mb={"8"}
                    alignItems={{ md: "center" }}
                >
                    <Heading fontSize={"2xl"}>
                        {intl.formatMessage({
                            defaultMessage: "Running cost (Monthly)",
                        })}
                    </Heading>
                    <Spacer />
                    <Button
                        mt={{ base: "4", md: "0" }}
                        leftIcon={<AiOutlinePlus />}
                        colorScheme={"blue"}
                        variant={"outline"}
                        onClick={() => {
                            if (addAllowed)
                                setIsAddAppDialogOpen(!isAddAppDialogOpen);
                            else onAddApp({});
                        }}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Add Apps",
                        })}
                    </Button>
                </Flex>

                <AddDialog
                    onClose={() => setIsAddAppDialogOpen(false)}
                    isOpen={isAddAppDialogOpen}
                    onAddAction={({ type, name }) => {
                        onAddApp({ type, name });
                        setIsAddAppDialogOpen(false);
                    }}
                    options={APPS}
                    optionsNames={APPS_NAMES}
                    title={intl.formatMessage({ defaultMessage: "Add an app" })}
                    placeholderText={intl.formatMessage({
                        defaultMessage: "Enter app name",
                    })}
                    actionText={intl.formatMessage({
                        defaultMessage: "Add app",
                    })}
                />
                <Heading fontSize={"2xl"} fontWeight={"normal"}>
                    {intl.formatMessage({
                        defaultMessage: "Apps Used",
                    })}
                </Heading>

                {Object.keys(running.apps).map((appKey, index) => (
                    <Box key={appKey}>
                        {index > 0 ? <Divider mb={"8"} /> : null}
                        <Dial
                            key={appKey}
                            title={running.apps[appKey].name}
                            value={running.apps[appKey].value}
                            onChange={(value) =>
                                onAppChange({ key: appKey, value })
                            }
                            onRemove={() => onRemoveApp({ key: appKey })}
                            pb={"4"}
                            currency={currency}
                        />
                    </Box>
                ))}
                <Divider />
                <LighthouseDial
                    value={{
                        unit: currency,
                        current: lighthouseCost,
                    }}
                    bestPlan={bestPlan}
                />
                <Divider />
                <Flex fontWeight={"bold"} color={"gray.600"}>
                    <Box>
                        {intl.formatMessage({
                            defaultMessage: "Total running cost",
                        })}
                    </Box>
                    <Spacer />
                    <Box>
                        {currencyFormat(intl, totalRunningCost, currency)}
                    </Box>
                </Flex>
                <Divider />
                <Dial
                    title={intl.formatMessage({
                        defaultMessage: "Revenue",
                    })}
                    value={running.revenue.value}
                    onChange={(value) => onRevenueChange({ value })}
                    currency={currency}
                />
            </Stack>
        </Card>
    );
};

export default Running;
