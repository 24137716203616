import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const UpfrontInvestment = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 45"
            aria-labelledby={"upfrontInvestmentTitle"}
            {...rest}
        >
            <title id={"upfrontInvestmentTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Business process automation",
                })}
            </title>
            <desc id={"upfrontInvestmentDesc"}>
                {intl.formatMessage({
                    defaultMessage:
                        "A graphic showing dollar sign inside rotating symbol.",
                })}
            </desc>
            <g
                data-name="[icon]-upfront-investment"
                transform="translate(.187 -.193)"
            >
                <g data-name="Group 19677">
                    <rect
                        width="46"
                        height="45"
                        fill="#ffe8ff"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 .193)"
                    ></rect>
                </g>
                <g fill="#c05bf9" transform="translate(10.331 9.782)">
                    <path
                        d="M113.239 105.535a7.7 7.7 0 107.7 7.7 7.713 7.713 0 00-7.7-7.7zm.589 10.3v.793h-1.007v-.757a4.2 4.2 0 01-1.836-.5l.543-1.229a3.431 3.431 0 001.65.457c.479 0 .65-.1.65-.272 0-.629-2.765-.121-2.765-1.986 0-.807.586-1.486 1.758-1.65v-.779h1.007v.764a3.732 3.732 0 011.45.414l-.507 1.222a3.085 3.085 0 00-1.393-.372c-.493 0-.65.143-.65.314 0 .6 2.765.1 2.765 1.943 0 .774-.557 1.446-1.665 1.639z"
                        data-name="Path 27010"
                        transform="translate(-100.132 -100.132)"
                    ></path>
                    <path
                        d="M50.462 228.182l-1.528.169a11.566 11.566 0 01-11.5 12.826 11.759 11.759 0 01-9.206-4.436h1.522V235.2h-4.11v4.3h1.537v-1.619a13.261 13.261 0 0014.42 4.153 13.109 13.109 0 008.867-13.854z"
                        data-name="Path 27011"
                        transform="translate(-24.325 -216.499)"
                    ></path>
                    <path
                        d="M3.275 7.019a11.582 11.582 0 016.158-4.875A11.448 11.448 0 0117 2.221a11.66 11.66 0 014.949 3.437h-1.46v1.537h4.155V3.089h-1.537v1.553A13.207 13.207 0 0017.524.774 12.971 12.971 0 008.945.687 13.109 13.109 0 00.107 14.779l1.524-.2a11.594 11.594 0 011.644-7.56z"
                        data-name="Path 27012"
                    ></path>
                </g>
            </g>
        </Icon>
    );
};

export default UpfrontInvestment;
