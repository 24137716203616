import React, { useState, useRef } from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Select,
    Input,
    Stack,
} from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

/**
 * @description Renders a dialog for adding options
 * @param {Object} props
 * @param {Object.<string, string>} props.options
 * @param {Object.<string, string>} props.optionsNames
 * @param {string} props.title
 * @param {string} props.actionText
 * @param {string} props.placeholderText
 * @param {function} props.isOpen
 * @param {function} props.onClose
 * @param {function} props.onAddAction
 * @returns {JSX.Element}
 */
const AddDialog = ({
    options,
    optionsNames,
    title,
    actionText,
    placeholderText,
    isOpen,
    onClose,
    onAddAction,
}) => {
    const intl = useIntl();
    const selectRef = useRef(null);
    const inputRef = useRef(null);
    const [currentOption, setCurrentOption] = useState(
        options[Object.keys(options)[0]]
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        <Select
                            ref={selectRef}
                            onChange={(event) =>
                                setCurrentOption(event.target.value)
                            }
                            defaultValue={currentOption}
                        >
                            {Object.values(options).map((opt) => {
                                return (
                                    <option value={opt} key={opt}>
                                        {optionsNames[opt]}
                                    </option>
                                );
                            })}
                        </Select>
                        {currentOption === options.OTHER && (
                            <Input
                                placeholder={placeholderText}
                                ref={inputRef}
                            />
                        )}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        {intl.formatMessage({ defaultMessage: "Close" })}
                    </Button>
                    <Button
                        variant="ghost"
                        onClick={() =>
                            onAddAction({
                                type: currentOption,
                                name:
                                    inputRef.current?.value &&
                                    inputRef.current?.value.length > 0
                                        ? inputRef.current?.value
                                        : optionsNames[selectRef.current.value],
                            })
                        }
                    >
                        {actionText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddDialog;
