import React from "react";

import {
    Box,
    Container,
    Button,
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    chakra,
    Stack,
    Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useIntl } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { FaEnvelope } from "react-icons/fa";

import { saveContactInfo } from "../../storage/contact";
import { submitToHubspot } from "./submit";
import { SIZES } from "../../constants";
import Card from "../Card";

const emailAddressPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const query = graphql`
    query {
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
    }
`;

/**
 * @description Renders a Contact section.
 * @returns {JSX.Element}
 */
const Contact = ({ ...rest }) => {
    const intl = useIntl();
    const { site } = useStaticQuery(query);
    const { title, siteUrl } = site.siteMetadata;

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm();

    const onSubmit = async (values) => {
        await submitToHubspot({
            ...values,
            pageName: title,
            pageUri: siteUrl,
        });
        await saveContactInfo(values);
    };

    return (
        <Box as={"section"} px={"4"} backgroundColor={"gray.50"} {...rest}>
            <Box as={Container} maxW={SIZES.containerMaxWidth} p={"10"}>
                <Heading
                    fontSize={"xl"}
                    fontWeight={"semibold"}
                    mb={"8"}
                    color={"blue.600"}
                >
                    {intl.formatMessage({
                        defaultMessage: "Subscribe to our newsletter",
                    })}
                </Heading>
                <chakra.form
                    onSubmit={handleSubmit(onSubmit)}
                    id={"contact-form"}
                >
                    <Stack
                        spacing={"4"}
                        direction={{ md: "row", base: "column" }}
                        alignItems={"center"}
                    >
                        <FormControl
                            isInvalid={errors.name}
                            isRequired
                            height={"24"}
                        >
                            <FormLabel htmlFor="name">
                                {intl.formatMessage({
                                    defaultMessage: "Name",
                                })}
                            </FormLabel>
                            <Input
                                id={"name"}
                                {...register("name", {
                                    required: intl.formatMessage({
                                        defaultMessage: "This is required",
                                    }),
                                })}
                            />
                            <FormErrorMessage>
                                {errors.name && errors.name.message}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl
                            isInvalid={errors.email}
                            isRequired
                            height={"24"}
                        >
                            <FormLabel htmlFor="email">
                                {intl.formatMessage({
                                    defaultMessage: "Email address",
                                })}
                            </FormLabel>
                            <Input
                                id={"email"}
                                {...register("email", {
                                    required: intl.formatMessage({
                                        defaultMessage: "This is required",
                                    }),
                                    pattern: {
                                        value: emailAddressPattern,
                                        message: intl.formatMessage({
                                            defaultMessage:
                                                "Please enter email address in correct format.",
                                        }),
                                    },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.email && errors.email.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={errors.company}
                            isRequired
                            height={"24"}
                        >
                            <FormLabel htmlFor="company">
                                {intl.formatMessage({
                                    defaultMessage: "Company Name",
                                })}
                            </FormLabel>
                            <Input
                                id={"company"}
                                {...register("company", {
                                    required: intl.formatMessage({
                                        defaultMessage: "This is required",
                                    }),
                                })}
                            />
                            <FormErrorMessage>
                                {errors.company && errors.company.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Button
                            type={"submit"}
                            form={"contact-form"}
                            isLoading={isSubmitting}
                            colorScheme={"blue"}
                            width={"100%"}
                            variant={"outline"}
                            leftIcon={<FaEnvelope />}
                        >
                            {intl.formatMessage({
                                defaultMessage: "Subscribe!",
                            })}
                        </Button>
                    </Stack>
                </chakra.form>
            </Box>
        </Box>
    );
};

export default Contact;
