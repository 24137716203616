import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const ProductivityGain = () => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="5em"
            height="5em"
            viewBox="0 0 81 81"
            aria-labelledby={"productivityGainTitle"}
        >
            <title id={"productivityGainTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Job automation calculator",
                })}
            </title>
            <desc id={"productivityGainDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing clock.",
                })}
            </desc>
            <g
                data-name="[icon]-productivity-gain"
                transform="translate(-1230 -4250)"
            >
                <rect
                    width="81"
                    height="81"
                    fill="#dbebf9"
                    data-name="Rectangle 3453"
                    rx="25"
                    transform="translate(1230 4250)"
                ></rect>
                <path
                    fill="#1277d6"
                    d="M16.141.563a15.578 15.578 0 1015.578 15.578A15.575 15.575 0 0016.141.563zm3.587 21.992l-5.54-4.026a.759.759 0 01-.308-.609V7.347a.756.756 0 01.754-.754h3.015a.756.756 0 01.754.754V16l3.989 2.9a.753.753 0 01.163 1.055l-1.771 2.437a.759.759 0 01-1.057.162z"
                    data-name="Icon awesome-clock"
                    transform="translate(1254.297 4274.297)"
                ></path>
            </g>
        </Icon>
    );
};

export default ProductivityGain;
