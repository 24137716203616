import React from "react";

import { Icon } from "@chakra-ui/react";
import { useIntl } from "gatsby-plugin-react-intl";

/**
 * @description Renders our hero illustration.
 * @returns {JSX.Element}
 */
const MainIllustration = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 661.558 536.54"
            aria-labelledby={"mainIllustrationTitle"}
            {...rest}
        >
            <title id={"mainIllustrationTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Automation RoI calculator",
                })}
            </title>
            <desc id="mainIllustrationDesc">
                {intl.formatMessage({
                    defaultMessage:
                        "Graphic showing automation calculation process.",
                })}
            </desc>
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="-3.752"
                    x2="-2.932"
                    y1="4.064"
                    y2="4.064"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#b37cff"></stop>
                    <stop offset="1" stopColor="#f895e7"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="-78.584"
                    x2="-77.265"
                    y1="14.386"
                    y2="14.386"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="-67.263"
                    x2="-66.424"
                    y1="13.275"
                    y2="13.275"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="-3.533"
                    x2="-2.677"
                    y1="2.969"
                    y2="2.969"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="-129.124"
                    x2="-127.785"
                    y1="25.58"
                    y2="25.58"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-6"
                    x1="2.522"
                    x2="6.157"
                    y1="-0.743"
                    y2="4.148"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#a737d5"></stop>
                    <stop offset="1" stopColor="#ef497a"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-7"
                    x1="-222.223"
                    x2="-221.333"
                    y1="45.033"
                    y2="45.033"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="-8.524"
                    x2="-7.777"
                    y1="8.492"
                    y2="8.492"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="-9.86"
                    x2="-8.855"
                    y1="6.917"
                    y2="6.917"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-10"
                    x1="-235.088"
                    x2="-233.616"
                    y1="43.105"
                    y2="43.105"
                    xlinkHref="#linear-gradient"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-11"
                    x1="2.763"
                    x2="8.196"
                    y1="-1.512"
                    y2="5.797"
                    xlinkHref="#linear-gradient-6"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-12"
                    x1="0.51"
                    x2="0.452"
                    y1="0.156"
                    y2="1.935"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#53d8ff"></stop>
                    <stop offset="1" stopColor="#3840f7"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-13"
                    x1="91.675"
                    x2="92.539"
                    y1="0.516"
                    y2="0.489"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e85f5a"></stop>
                    <stop offset="1" stopColor="#eec9c8"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-14"
                    x1="1.134"
                    x2="0.373"
                    y1="-0.314"
                    y2="0.502"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#eec9c8"></stop>
                    <stop offset="0.25" stopColor="#edc3c2"></stop>
                    <stop offset="0.57" stopColor="#ebb3b2"></stop>
                    <stop offset="0.926" stopColor="#e89a98"></stop>
                    <stop offset="1" stopColor="#e89492"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-15"
                    x1="0.393"
                    x2="0.218"
                    y1="0.704"
                    y2="1.098"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e89492"></stop>
                    <stop offset="0.074" stopColor="#e89a98"></stop>
                    <stop offset="0.43" stopColor="#ebb3b2"></stop>
                    <stop offset="0.75" stopColor="#edc3c2"></stop>
                    <stop offset="1" stopColor="#eec9c8"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-16"
                    x1="1.177"
                    x2="-0.02"
                    y1="0.979"
                    y2="0.253"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fa902f"></stop>
                    <stop offset="0.168" stopColor="#f7a02f"></stop>
                    <stop offset="0.455" stopColor="#f3b530"></stop>
                    <stop offset="0.735" stopColor="#f0c130"></stop>
                    <stop offset="1" stopColor="#f0c631"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-17"
                    x1="0.624"
                    x2="-0.328"
                    y1="0.495"
                    y2="0.51"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#172b36"></stop>
                    <stop offset="1" stopColor="#325d75"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-18"
                    x1="0.52"
                    x2="0.105"
                    y1="0.334"
                    y2="1.163"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#0056ec"></stop>
                    <stop offset="1" stopColor="#008bec"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-19"
                    x1="0.517"
                    x2="0.459"
                    y1="0.263"
                    y2="1.092"
                    xlinkHref="#linear-gradient-15"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-20"
                    x1="0.098"
                    x2="1.038"
                    y1="0.075"
                    y2="0.024"
                    xlinkHref="#linear-gradient-15"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-21"
                    x1="1"
                    x2="0"
                    y1="0.5"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff5dc"></stop>
                    <stop offset="0.342" stopColor="#fff6df"></stop>
                    <stop offset="0.672" stopColor="#fff9eb"></stop>
                    <stop offset="0.996" stopColor="#fffefe"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-22"
                    x1="0.434"
                    x2="0.289"
                    y1="0.689"
                    y2="1.063"
                    xlinkHref="#linear-gradient-15"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-23"
                    x1="1.033"
                    x2="-0.033"
                    y1="1.076"
                    y2="0.202"
                    xlinkHref="#linear-gradient-16"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-24"
                    x1="0.612"
                    x2="-0.334"
                    y1="0.492"
                    y2="0.516"
                    xlinkHref="#linear-gradient-17"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-25"
                    x1="1"
                    x2="-0.176"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-18"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-26"
                    x1="0.372"
                    x2="1.423"
                    y1="0.508"
                    y2="0.457"
                    xlinkHref="#linear-gradient-15"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-28"
                    x1="0.906"
                    x2="0.181"
                    y1="0.639"
                    y2="0.31"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#d0471e"></stop>
                    <stop offset="0.22" stopColor="#d95e12"></stop>
                    <stop offset="0.488" stopColor="#e17308"></stop>
                    <stop offset="0.751" stopColor="#e67f02"></stop>
                    <stop offset="1" stopColor="#e88400"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-29"
                    x1="-12.545"
                    x2="-15.38"
                    y1="-0.484"
                    y2="6.012"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#cb236d"></stop>
                    <stop offset="1" stopColor="#4c246d"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-30"
                    x1="-56.359"
                    x2="-55.359"
                    y1="0.5"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#f9b776"></stop>
                    <stop offset="1" stopColor="#f47960"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-31"
                    x1="-17.781"
                    x2="-16.781"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-29"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-32"
                    x1="-36.426"
                    x2="-43.443"
                    y1="-2.223"
                    y2="5.384"
                    xlinkHref="#linear-gradient-29"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-33"
                    x1="-73.782"
                    x2="-72.782"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-34"
                    x1="-13.151"
                    x2="-12.151"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-29"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-35"
                    x1="-8.616"
                    x2="-7.616"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-36"
                    x1="92.205"
                    x2="94.205"
                    y1="1.605"
                    y2="4.278"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-37"
                    x1="-54.331"
                    x2="-52.312"
                    y1="0.706"
                    y2="-1.072"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-38"
                    x1="-10.057"
                    x2="-9.057"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-39"
                    x1="-7.273"
                    x2="-6.76"
                    y1="-0.062"
                    y2="2.428"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#2b3582"></stop>
                    <stop offset="1" stopColor="#150e42"></stop>
                </linearGradient>
                <linearGradient
                    id="linear-gradient-40"
                    x1="-37.934"
                    x2="-38.507"
                    y1="-3.497"
                    y2="1.805"
                    xlinkHref="#linear-gradient-12"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-41"
                    x1="-19.823"
                    x2="-19.966"
                    y1="-1.235"
                    y2="1.146"
                    xlinkHref="#linear-gradient-12"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-42"
                    x1="-28.548"
                    x2="-27.548"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-43"
                    x1="-23.082"
                    x2="-22.082"
                    y1="0.5"
                    y2="0.5"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-44"
                    x1="-25.897"
                    x2="-27.106"
                    y1="-15.502"
                    y2="-14.208"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-45"
                    x1="-19.186"
                    x2="-18.149"
                    y1="-9.685"
                    y2="-9.685"
                    xlinkHref="#linear-gradient-39"
                ></linearGradient>
                <linearGradient
                    id="linear-gradient-46"
                    x1="-78.929"
                    x2="-76.241"
                    y1="-47.58"
                    y2="-50.122"
                    xlinkHref="#linear-gradient-30"
                ></linearGradient>
            </defs>
            <g
                data-name="[illustration] - process-automation-header-image"
                transform="translate(-373.129 -455)"
            >
                <path
                    fill="#294e81"
                    d="M989.469 911.683c-33.628 35.473-78.255 61.486-131.794 71.988-123.276 24.181-289.906-12.678-327.431-148.6-22.871-82.841-6.857-179.733 40.123-251.511C622 504.668 715.225 451.432 810.631 455.186c93.347 3.674 184.843 77.157 223.515 158.887 48.768 103.066 27.105 221.89-44.677 297.61z"
                    data-name="Path 27019"
                    opacity="0.3"
                    transform="translate(-51.425)"
                ></path>
                <g
                    data-name="Group 19694"
                    transform="translate(373.129 492.785)"
                >
                    <g data-name="Group 19689">
                        <g data-name="Group 19686" opacity="0.3">
                            <path
                                fill="url(#linear-gradient)"
                                d="M729.856 593.838a2.776 2.776 0 01-3.814-.94 105.897 105.897 0 00-4.98-7.493 2.777 2.777 0 01.568-3.847l.043-.033a2.777 2.777 0 013.88.612c1.855 2.549 3.619 5.2 5.242 7.888a2.778 2.778 0 01-.7 3.648 2.606 2.606 0 01-.239.165zM711.673 574.2a107.226 107.226 0 00-6.5-6.213 2.777 2.777 0 113.663-4.175 112.175 112.175 0 016.844 6.537 2.778 2.778 0 01-.077 3.927 2.876 2.876 0 01-.251.214 2.777 2.777 0 01-3.679-.29zm-18.129-15.058c-2.5-1.637-5.1-3.186-7.725-4.6a2.777 2.777 0 112.636-4.889 110.386 110.386 0 018.135 4.849 2.777 2.777 0 11-3.046 4.644zm-21.044-10.62c-2.8-1.04-5.684-1.969-8.562-2.764a2.777 2.777 0 011.478-5.354c3.031.837 6.064 1.816 9.015 2.909a2.778 2.778 0 01-1.93 5.209zm-22.884-5.672c-2.957-.39-5.973-.659-8.963-.8a2.777 2.777 0 11.259-5.548c3.146.146 6.319.43 9.43.84a2.777 2.777 0 11-.727 5.506zm-23.569-.467c-2.974.274-5.973.679-8.915 1.2a2.777 2.777 0 01-.977-5.468c3.1-.553 6.253-.979 9.383-1.268a2.777 2.777 0 01.509 5.531zm-86.7 55.845a2.777 2.777 0 01-1.12-3.765c1.5-2.763 3.133-5.5 4.866-8.129a2.777 2.777 0 014.639 3.054c-1.645 2.5-3.2 5.1-4.62 7.72a2.775 2.775 0 01-3.765 1.12zm63.631-51.078c-2.837.928-5.669 1.99-8.416 3.159a2.777 2.777 0 11-2.174-5.112 110.585 110.585 0 018.863-3.326 2.777 2.777 0 111.726 5.279zm-50.3 30.877a2.778 2.778 0 01-.249-3.92 112.722 112.722 0 016.555-6.835 2.777 2.777 0 013.843 4.011 105.543 105.543 0 00-6.229 6.5 2.779 2.779 0 01-3.92.248zm28.874-21.076a105.803 105.803 0 00-7.5 4.962 2.777 2.777 0 01-3.307-4.462l.048-.035a109.952 109.952 0 017.9-5.225 2.778 2.778 0 013.112 4.592c-.084.058-.167.117-.253.166z"
                                data-name="Path 27020"
                                transform="translate(-530.196 -536.5)"
                            ></path>
                            <path
                                fill="url(#linear-gradient-2)"
                                d="M514.242 802.227a2.759 2.759 0 00.962-1.352c.443-1.362.923-2.736 1.424-4.087a2.777 2.777 0 10-5.206-1.933 112.858 112.858 0 00-1.5 4.3 2.777 2.777 0 004.32 3.073z"
                                data-name="Path 27021"
                                transform="translate(-509.785 -722.809)"
                            ></path>
                        </g>
                        <g
                            data-name="Group 19687"
                            opacity="0.3"
                            transform="translate(5.279 136.835)"
                        >
                            <path
                                fill="url(#linear-gradient-3)"
                                d="M535.533 1110.969a2.778 2.778 0 00.772-3.518 104.101 104.101 0 01-1.955-3.861 2.777 2.777 0 10-5.007 2.406c.657 1.367 1.35 2.735 2.058 4.066a2.777 2.777 0 003.757 1.146 2.836 2.836 0 00.375-.239z"
                                data-name="Path 27022"
                                transform="translate(-529.068 -1084.028)"
                            ></path>
                            <path
                                fill="url(#linear-gradient-4)"
                                d="M717.847 1149.822a2.777 2.777 0 11-3.132-4.587 108.032 108.032 0 007.1-5.295 2.778 2.778 0 013.5 4.313q-1.5 1.218-3.028 2.375a119.07 119.07 0 01-4.44 3.194zm14.619-15.756a2.776 2.776 0 01-.125-3.925c2.014-2.148 3.959-4.4 5.781-6.706a2.777 2.777 0 114.356 3.446 111.107 111.107 0 01-6.086 7.059 2.828 2.828 0 01-.352.317 2.778 2.778 0 01-3.574-.192zm-27.672 23.415a111.561 111.561 0 01-8.51 3.8 2.777 2.777 0 11-2.047-5.163c2.736-1.084 5.454-2.3 8.081-3.606a2.777 2.777 0 012.918 4.7 2.814 2.814 0 01-.443.268zm42.44-42.1a2.776 2.776 0 01-.985-3.8 105.424 105.424 0 004.163-7.81 2.777 2.777 0 015.008 2.4 110.894 110.894 0 01-4.384 8.225 2.773 2.773 0 01-3.8.985zm-65.357 50.494c-3 .735-6.072 1.351-9.133 1.833a2.777 2.777 0 11-.863-5.486 105.648 105.648 0 008.675-1.741 2.777 2.777 0 111.322 5.395zm-24.2 3.162c-3.1.062-6.228-.007-9.313-.2a2.777 2.777 0 01.353-5.543c2.931.186 5.909.251 8.85.194a2.777 2.777 0 11.11 5.553zm-24.318-2.188a111.975 111.975 0 01-9.047-2.226 2.777 2.777 0 011.551-5.333 105.96 105.96 0 008.6 2.116 2.777 2.777 0 11-1.1 5.444zm-23.261-7.44a110.576 110.576 0 01-8.343-4.158 2.777 2.777 0 112.685-4.861 105.098 105.098 0 007.923 3.948 2.777 2.777 0 11-2.265 5.071zm-21.068-12.376a111.456 111.456 0 01-7.217-5.89 2.777 2.777 0 113.69-4.152c2.2 1.954 4.505 3.836 6.855 5.594a2.777 2.777 0 11-3.328 4.447zm-17.818-16.683a110.654 110.654 0 01-4.3-5.335q-.745-.985-1.465-1.979a2.746 2.746 0 01.622-3.877 2.777 2.777 0 013.878.622q.684.945 1.392 1.881c1.3 1.723 2.681 3.429 4.09 5.072a2.777 2.777 0 01-.3 3.916 2.75 2.75 0 01-.134.108 2.777 2.777 0 01-3.786-.412z"
                                data-name="Path 27023"
                                transform="translate(-555.093 -1084.153)"
                            ></path>
                            <path
                                fill="url(#linear-gradient-5)"
                                d="M1265.962 1045.558a2.767 2.767 0 00.967-1.365c.458-1.432.9-2.9 1.3-4.365a2.777 2.777 0 10-5.354-1.475c-.384 1.391-.8 2.786-1.234 4.146a2.777 2.777 0 004.323 3.059z"
                                data-name="Path 27024"
                                transform="translate(-1060.985 -1036.312)"
                            ></path>
                        </g>
                        <g
                            data-name="Group 19688"
                            transform="translate(21.525 26.68)"
                        >
                            <path
                                fill="url(#linear-gradient-6)"
                                d="M755.393 711a1.3 1.3 0 00-.83-1.258l-14.738-5.689a68.829 68.829 0 00-7.772-20.9l7.425-13.933a1.3 1.3 0 00-.195-1.495l-12.9-13.877a1.3 1.3 0 00-1.476-.3l-14.435 6.4a68.814 68.814 0 00-20.279-9.268l-4.6-15.112a1.3 1.3 0 00-1.195-.919l-18.937-.688a1.3 1.3 0 00-1.258.83l-5.689 14.738a68.8 68.8 0 00-20.9 7.772l-13.933-7.426a1.3 1.3 0 00-1.494.2L608.3 662.97a1.3 1.3 0 00-.3 1.476l6.395 14.435a68.809 68.809 0 00-9.268 20.279l-15.112 4.6a1.3 1.3 0 00-.919 1.195l-.686 18.945a1.3 1.3 0 00.83 1.258l14.739 5.689a68.8 68.8 0 007.771 20.9l-7.425 13.933a1.3 1.3 0 00.195 1.494l12.9 13.878a1.3 1.3 0 001.476.3l14.435-6.395a68.815 68.815 0 0020.279 9.268l4.6 15.112a1.3 1.3 0 001.195.919l18.937.688a1.3 1.3 0 001.258-.83l5.689-14.739a68.813 68.813 0 0020.9-7.772l13.933 7.425a1.3 1.3 0 001.494-.195l13.877-12.9a1.3 1.3 0 00.3-1.476l-6.395-14.435a68.822 68.822 0 009.267-20.279l15.112-4.6a1.3 1.3 0 00.919-1.195z"
                                data-name="Path 27025"
                                transform="translate(-588.409 -633.955)"
                            ></path>
                            <path
                                fill="#fff"
                                d="M851.99 905.107a19.035 19.035 0 112.348-1.521 19.069 19.069 0 01-2.348 1.521zm-23.183-20.786a14.6 14.6 0 107-8.718 14.815 14.815 0 00-1.818 1.178 14.5 14.5 0 00-5.182 7.54z"
                                data-name="Path 27026"
                                opacity="0.56"
                                transform="translate(-759.335 -804.915)"
                            ></path>
                            <path
                                fill="#fff"
                                d="M795.885 851.968a47.683 47.683 0 003.685-3.1q.559-.519 1.1-1.057a47.166 47.166 0 004.89-5.688l.06-.082.169-.235c.11-.153.218-.306.325-.46a1.111 1.111 0 10-1.822-1.271 44.664 44.664 0 01-47.9 17.646A44.334 44.334 0 01735 844.954a45.2 45.2 0 01-2.953-3.515 44.617 44.617 0 0168.3-57.246 44.624 44.624 0 0110.971 21.082 1.111 1.111 0 102.173-.462 46.84 46.84 0 10-83.214 37.964 47.8 47.8 0 003.1 3.69 46.846 46.846 0 0062.508 5.5z"
                                data-name="Path 27027"
                                opacity="0.56"
                                transform="translate(-684.584 -731.128)"
                            ></path>
                        </g>
                    </g>
                    <g
                        data-name="Group 19693"
                        transform="translate(194.674 8.539)"
                    >
                        <g
                            data-name="Group 19690"
                            opacity="0.3"
                            transform="translate(1.404)"
                        >
                            <path
                                fill="url(#linear-gradient-7)"
                                d="M1665.678 730.785a1.715 1.715 0 00.567-1.976 72.66 72.66 0 00-1.679-4.055 1.715 1.715 0 00-3.129 1.407 69.23 69.23 0 011.6 3.866 1.716 1.716 0 002.212 1 1.748 1.748 0 00.429-.242z"
                                data-name="Path 27028"
                                transform="translate(-1542.115 -681.019)"
                            ></path>
                            <path
                                fill="url(#linear-gradient-8)"
                                d="M1343.619 605.7a1.716 1.716 0 01-2.357-.575 71.191 71.191 0 00-4.048-5.951q-.456-.6-.929-1.2a1.716 1.716 0 01.282-2.409 1.775 1.775 0 012.41.282q.494.625.972 1.256a74.374 74.374 0 014.244 6.24 1.715 1.715 0 01-.428 2.257 1.344 1.344 0 01-.146.1zm-13.144-14.216a70.963 70.963 0 00-6.557-5.732 1.715 1.715 0 112.095-2.717 74.373 74.373 0 016.874 6.009 1.717 1.717 0 01.014 2.427 1.754 1.754 0 01-.186.162 1.715 1.715 0 01-2.24-.148zm-13.775-10.61a70.3 70.3 0 00-7.766-3.946 1.715 1.715 0 011.362-3.149 73.959 73.959 0 018.145 4.138 1.716 1.716 0 01-1.741 2.957zm-15.961-6.9a70.718 70.718 0 00-8.493-1.925 1.716 1.716 0 01.549-3.387 74.335 74.335 0 018.907 2.019 1.716 1.716 0 01-.963 3.293zm-17.162-2.8a71.477 71.477 0 00-8.707.2 1.715 1.715 0 11-.286-3.419 75.007 75.007 0 019.125-.206 1.716 1.716 0 01-.133 3.429zm-17.321 1.455a70.393 70.393 0 00-8.388 2.312 1.716 1.716 0 01-1.115-3.245 73.9 73.9 0 018.8-2.424 1.715 1.715 0 11.7 3.358zm-16.432 5.644a70.473 70.473 0 00-7.57 4.3 1.716 1.716 0 01-1.876-2.874 74.525 74.525 0 017.938-4.511 1.716 1.716 0 011.792 2.907 1.74 1.74 0 01-.284.179zm-23.279 15.62a1.715 1.715 0 01-.093-2.423 74.666 74.666 0 015.693-5.524c.3-.266.606-.526.912-.785a1.716 1.716 0 012.211 2.625c-.288.242-.573.487-.861.741a71.056 71.056 0 00-5.438 5.274 1.7 1.7 0 01-.224.2 1.716 1.716 0 01-2.2-.107z"
                                data-name="Path 27029"
                                transform="translate(-1225.996 -567.692)"
                            ></path>
                        </g>
                        <g
                            data-name="Group 19691"
                            opacity="0.3"
                            transform="translate(22.561 91.642)"
                        >
                            <path
                                fill="url(#linear-gradient-9)"
                                d="M1380.866 977.151a74.606 74.606 0 01-7.536 4.881 1.716 1.716 0 11-1.687-2.988 70.93 70.93 0 007.189-4.655 1.715 1.715 0 012.054 2.748zm4.474-5.829a1.715 1.715 0 01.074-2.424 71.673 71.673 0 005.877-6.239 1.716 1.716 0 012.634 2.2 74.922 74.922 0 01-6.16 6.537 1.711 1.711 0 01-2.425-.073zm-20.091 14.643a74.455 74.455 0 01-8.5 2.916 1.716 1.716 0 01-.916-3.307 71.176 71.176 0 008.1-2.779 1.715 1.715 0 111.307 3.171zm31.63-27.836a1.716 1.716 0 01-.511-2.371 70.444 70.444 0 004.2-7.471 1.715 1.715 0 113.088 1.494 73.571 73.571 0 01-4.4 7.836 1.715 1.715 0 01-2.371.512zm-48.907 32.611a74.574 74.574 0 01-8.946.786 1.716 1.716 0 11-.093-3.43 70.615 70.615 0 008.532-.75 1.716 1.716 0 01.507 3.394zm-17.922.492a75.048 75.048 0 01-8.877-1.376 1.715 1.715 0 01.73-3.351 71.771 71.771 0 008.468 1.311 1.716 1.716 0 11-.321 3.416zm-17.525-3.814a74.189 74.189 0 01-8.291-3.467 1.715 1.715 0 011.513-3.079 70.162 70.162 0 007.905 3.305 1.715 1.715 0 011.056 2.184 1.716 1.716 0 01-2.184 1.057z"
                                data-name="Path 27030"
                                transform="translate(-1303.275 -935.03)"
                            ></path>
                            <path
                                fill="url(#linear-gradient-10)"
                                d="M1673.043 909.607a1.71 1.71 0 00.587-.814c.474-1.39.907-2.8 1.29-4.192a1.716 1.716 0 10-3.308-.911 71.285 71.285 0 01-1.23 4 1.716 1.716 0 002.661 1.918z"
                                data-name="Path 27031"
                                transform="translate(-1569.812 -902.43)"
                            ></path>
                        </g>
                        <g
                            data-name="Group 19692"
                            transform="translate(0 17.787)"
                        >
                            <path
                                fill="url(#linear-gradient-11)"
                                d="M1332.606 684.218a.87.87 0 00-.555-.842l-9.863-3.807a46.051 46.051 0 00-5.2-13.983l4.969-9.324a.869.869 0 00-.13-1l-8.635-9.286a.867.867 0 00-.987-.2l-9.66 4.279a46.023 46.023 0 00-13.57-6.2l-3.081-10.112a.869.869 0 00-.8-.615l-12.671-.461a.869.869 0 00-.842.556l-3.807 9.862a46.042 46.042 0 00-13.984 5.2l-9.323-4.969a.868.868 0 00-1 .131l-9.286 8.635a.868.868 0 00-.2.988l4.279 9.659a46.044 46.044 0 00-6.2 13.57l-10.113 3.081a.869.869 0 00-.615.8l-.46 12.672a.869.869 0 00.555.842l9.863 3.807a46.023 46.023 0 005.2 13.984l-4.969 9.323a.869.869 0 00.131 1l8.635 9.286a.868.868 0 00.988.2l9.66-4.279a46.044 46.044 0 0013.57 6.2l3.081 10.113a.869.869 0 00.8.615l12.672.46a.869.869 0 00.842-.555l3.806-9.862a46.052 46.052 0 0013.984-5.2l9.323 4.969a.869.869 0 001-.13l9.286-8.635a.868.868 0 00.2-.988l-4.279-9.659a46.059 46.059 0 006.2-13.57l10.113-3.081a.868.868 0 00.615-.8z"
                                data-name="Path 27032"
                                transform="translate(-1220.867 -632.663)"
                            ></path>
                            <path
                                fill="#fff"
                                d="M1396.769 813.7a12.927 12.927 0 111.614-1.046 13.3 13.3 0 01-1.614 1.046zm-14.61-6.784a9.544 9.544 0 0012.959 3.777 9.545 9.545 0 00-9.181-16.736 9.717 9.717 0 00-1.176.762 9.56 9.56 0 00-2.602 12.196z"
                                data-name="Path 27033"
                                opacity="0.56"
                                transform="translate(-1334.657 -746.455)"
                            ></path>
                            <path
                                fill="#fff"
                                d="M1357.735 776.679a32.626 32.626 0 006.86-7.061l.17-.242a1.715 1.715 0 10-2.813-1.963 28.981 28.981 0 01-4.036 4.639l-.249.229a28.6 28.6 0 01-12.474 6.67 29.036 29.036 0 01-7.733.823h-.064l-.2-.007a28.694 28.694 0 01-20.1-9.2 29.229 29.229 0 01-1.912-2.275 28.884 28.884 0 1151.315-23.413 1.694 1.694 0 00.31.679 1.715 1.715 0 003.045-1.392 32.315 32.315 0 10-57.409 26.192 32.985 32.985 0 002.138 2.546 32.361 32.361 0 0043.152 3.775z"
                                data-name="Path 27034"
                                opacity="0.56"
                                transform="translate(-1282.646 -695.066)"
                            ></path>
                        </g>
                    </g>
                </g>
                <g
                    data-name="Group 19716"
                    transform="translate(467.749 593.374)"
                >
                    <g data-name="Group 19695">
                        <path
                            fill="url(#linear-gradient-12)"
                            d="M516.063 1168.363v237.422a21.965 21.965 0 0021.636 21.962l484.517 5.986a21.376 21.376 0 0021.7-21.295l.811-235.3a15.634 15.634 0 00-15.253-15.687l-496.06-10.021a16.939 16.939 0 00-17.351 16.933z"
                            data-name="Path 27037"
                            transform="translate(-516.063 -1151.424)"
                        ></path>
                        <g
                            fill="#fff"
                            stroke="#707070"
                            strokeWidth="1"
                            data-name="Rectangle 3459"
                            opacity="0.2"
                            transform="translate(31.711 64.165)"
                        >
                            <rect
                                width="234"
                                height="134"
                                stroke="none"
                                rx="15"
                            ></rect>
                            <rect
                                width="233"
                                height="133"
                                x="0.5"
                                y="0.5"
                                fill="none"
                                rx="14.5"
                            ></rect>
                        </g>
                    </g>
                    <rect
                        width="185"
                        height="239"
                        fill="#0028aa"
                        data-name="Rectangle 3442"
                        rx="15"
                        transform="translate(309.711 27.165)"
                    ></rect>
                    <rect
                        width="128"
                        height="46"
                        fill="#00007f"
                        data-name="Rectangle 3443"
                        rx="10"
                        transform="translate(338.711 66.165)"
                    ></rect>
                    <rect
                        width="128"
                        height="45"
                        fill="#00007f"
                        data-name="Rectangle 3444"
                        rx="10"
                        transform="translate(338.711 132.165)"
                    ></rect>
                    <rect
                        width="128"
                        height="46"
                        fill="#00007f"
                        data-name="Rectangle 3445"
                        rx="10"
                        transform="translate(338.711 196.165)"
                    ></rect>
                    <text
                        fill="#fff"
                        data-name="1835"
                        fontFamily="Overpass-Bold, Overpass"
                        fontSize="19"
                        fontWeight="700"
                        transform="translate(379.711 94.165)"
                    >
                        <tspan x="0" y="0">
                            1835
                        </tspan>
                    </text>
                    <text
                        fill="#fff"
                        data-name="$76,589"
                        fontFamily="Overpass-Bold, Overpass"
                        fontSize="19"
                        fontWeight="700"
                        transform="translate(363.711 159.165)"
                    >
                        <tspan x="0" y="0">
                            $76,589
                        </tspan>
                    </text>
                    <text
                        fill="#fff"
                        data-name="$21,585"
                        fontFamily="Overpass-Bold, Overpass"
                        fontSize="19"
                        fontWeight="700"
                        transform="translate(365.711 224.165)"
                    >
                        <tspan x="0" y="0">
                            $21,585
                        </tspan>
                    </text>
                    <path
                        fill="#fff"
                        d="M0 0H147V14H0z"
                        data-name="Rectangle 3446"
                        opacity="0.5"
                        transform="translate(32.711 35.165)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M0 0H65V11H0z"
                        data-name="Rectangle 3447"
                        opacity="0.5"
                        transform="translate(370.711 43.165)"
                    ></path>
                    <g
                        data-name="Group 19745"
                        transform="translate(46.385 86.829)"
                    >
                        <path
                            fill="#fff"
                            d="M0 0H198V4H0z"
                            data-name="Rectangle 3457"
                            transform="translate(.327 4.336)"
                        ></path>
                        <path
                            fill="#2a3481"
                            d="M0 0H36V4H0z"
                            data-name="Rectangle 3458"
                            transform="translate(.327 4.336)"
                        ></path>
                        <circle
                            cx="6"
                            cy="6"
                            r="6"
                            fill="#fff"
                            data-name="Ellipse 38"
                            transform="translate(30.327 .336)"
                        ></circle>
                    </g>
                    <g
                        fill="#fff"
                        data-name="Group 19746"
                        transform="translate(40.732 112.264)"
                    >
                        <path
                            d="M0 0H198V4H0z"
                            data-name="Rectangle 3457"
                            transform="translate(5.979 4.9)"
                        ></path>
                        <circle
                            cx="6"
                            cy="6"
                            r="6"
                            data-name="Ellipse 38"
                            transform="translate(-.021 -.1)"
                        ></circle>
                    </g>
                    <g
                        data-name="Group 19747"
                        transform="translate(46.385 137.7)"
                    >
                        <path
                            fill="#fff"
                            d="M0 0H198V4H0z"
                            data-name="Rectangle 3457"
                            transform="translate(.327 4.465)"
                        ></path>
                        <path
                            fill="#2a3481"
                            d="M0 0H109V4H0z"
                            data-name="Rectangle 3458"
                            transform="translate(.327 4.465)"
                        ></path>
                        <ellipse
                            cx="6.5"
                            cy="6"
                            fill="#fff"
                            data-name="Ellipse 38"
                            rx="6.5"
                            ry="6"
                            transform="translate(102.327 .465)"
                        ></ellipse>
                    </g>
                    <g
                        data-name="Group 19748"
                        transform="translate(46.385 172.556)"
                    >
                        <path
                            fill="#fff"
                            d="M0 0H198V4H0z"
                            data-name="Rectangle 3457"
                            transform="translate(.327 3.609)"
                        ></path>
                        <path
                            fill="#2a3481"
                            d="M0 0H75V4H0z"
                            data-name="Rectangle 3458"
                            transform="translate(.327 3.609)"
                        ></path>
                        <circle
                            cx="6.5"
                            cy="6.5"
                            r="6.5"
                            fill="#fff"
                            data-name="Ellipse 38"
                            transform="translate(68.327 -.391)"
                        ></circle>
                    </g>
                </g>
                <g
                    data-name="Group 14341"
                    transform="translate(427.173 755.568)"
                >
                    <path
                        fill="url(#linear-gradient-13)"
                        d="M652.919 346.818c.451-.007 9.011-7.488 9.011-7.488l-2.36-8.737s-7.343 2.853-7.6 5.884-2.231 10.385.949 10.341z"
                        data-name="Path 21031"
                        transform="translate(-586.144 -280.518)"
                    ></path>
                    <g
                        data-name="Group 14331"
                        transform="translate(67.964 33.144)"
                    >
                        <path
                            fill="#005ff0"
                            d="M608.848 351.334h44.512a3.584 3.584 0 003.188-2.441l9.322-30.443a1.744 1.744 0 00-1.695-2.441h-44.514a3.587 3.587 0 00-3.188 2.441l-9.322 30.441a1.743 1.743 0 001.7 2.443z"
                            data-name="Path 21032"
                            transform="translate(-607.003 -316.005)"
                        ></path>
                        <path
                            fill="#b3cdfa"
                            d="M653.564 349.946a1.8 1.8 0 001.6-1.221l9.318-30.444a.872.872 0 00-.846-1.22h-44.517a1.8 1.8 0 00-1.593 1.22l-9.319 30.444a.873.873 0 00.848 1.221z"
                            data-name="Path 21033"
                            transform="translate(-606.834 -315.837)"
                        ></path>
                        <path
                            fill="#b3cdfa"
                            d="M634.742 346.255h4.979a.443.443 0 00.395-.3v-.006a.216.216 0 00-.21-.3h-4.979a.444.444 0 00-.4.3v.006a.216.216 0 00.21.3z"
                            data-name="Path 21034"
                            transform="translate(-613.032 -311.244)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14332"
                        transform="translate(106.859 45.744)"
                    >
                        <path
                            fill="#fff"
                            d="M619.162 336.99a7.208 7.208 0 006.41-4.894 4.189 4.189 0 00-.482-3.754 3.824 3.824 0 00-3.184-1.478 7.216 7.216 0 00-6.412 4.893 4.2 4.2 0 00.483 3.754 3.829 3.829 0 003.185 1.479zm2.744-8.966a2.7 2.7 0 012.253 1.008 3 3 0 01.3 2.724 5.975 5.975 0 01-5.3 4.073 2.7 2.7 0 01-2.253-1.006 3.017 3.017 0 01-.306-2.727 5.979 5.979 0 015.305-4.072z"
                            data-name="Path 21035"
                            transform="translate(-615.254 -326.861)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14333"
                        transform="translate(113.511 45.163)"
                    >
                        <path
                            fill="#005ff0"
                            d="M618.99 332.347a4.765 4.765 0 00-.57-4.272 4.393 4.393 0 00-3.651-1.712v2.322a2.134 2.134 0 011.784.772 2.455 2.455 0 01.216 2.21z"
                            data-name="Path 21036"
                            transform="translate(-614.769 -326.36)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14334"
                        transform="translate(92.194 45.745)"
                    >
                        <path
                            fill="#fff"
                            d="M631.8 336.99a7.209 7.209 0 006.411-4.894 4.2 4.2 0 00-.483-3.754 3.829 3.829 0 00-3.184-1.476 7.209 7.209 0 00-6.41 4.893 4.187 4.187 0 00.482 3.754 3.825 3.825 0 003.184 1.477zm2.744-8.966a2.7 2.7 0 012.253 1.006 3.012 3.012 0 01.3 2.726 5.976 5.976 0 01-5.3 4.073 2.7 2.7 0 01-2.253-1.008 3.01 3.01 0 01-.3-2.726 5.974 5.974 0 015.3-4.071z"
                            data-name="Path 21037"
                            transform="translate(-627.89 -326.863)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14335"
                        transform="translate(98.845 45.163)"
                    >
                        <path
                            fill="#005ff0"
                            d="M631.627 332.347a4.767 4.767 0 00-.57-4.272 4.4 4.4 0 00-3.652-1.712v2.322a2.134 2.134 0 011.784.772 2.451 2.451 0 01.218 2.21z"
                            data-name="Path 21038"
                            transform="translate(-627.405 -326.36)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14336"
                        transform="translate(77.527 45.744)"
                    >
                        <path
                            fill="#fff"
                            d="M644.432 336.99a7.208 7.208 0 006.41-4.894 4.186 4.186 0 00-.482-3.754 3.824 3.824 0 00-3.184-1.478 7.214 7.214 0 00-6.411 4.893 4.2 4.2 0 00.483 3.754 3.83 3.83 0 003.185 1.479zm2.744-8.966a2.7 2.7 0 012.253 1.008 3 3 0 01.3 2.724 5.972 5.972 0 01-5.3 4.073 2.694 2.694 0 01-2.253-1.006 3.013 3.013 0 01-.306-2.727 5.978 5.978 0 015.3-4.072z"
                            data-name="Path 21039"
                            transform="translate(-640.524 -326.861)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14337"
                        transform="translate(84.179 45.163)"
                    >
                        <path
                            fill="#005ff0"
                            d="M644.261 332.347a4.769 4.769 0 00-.568-4.272 4.394 4.394 0 00-3.652-1.712v2.322a2.134 2.134 0 011.784.772 2.456 2.456 0 01.217 2.21z"
                            data-name="Path 21040"
                            transform="translate(-640.041 -326.36)"
                        ></path>
                    </g>
                    <path
                        fill="#fff"
                        d="M617.822 332.285h4.043l.567-1.853h-4.043z"
                        data-name="Path 21041"
                        opacity="0.5"
                        transform="translate(-507.987 -280.546)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M630.457 332.285h4.043l.568-1.853h-4.043z"
                        data-name="Path 21042"
                        opacity="0.5"
                        transform="translate(-535.289 -280.546)"
                    ></path>
                    <path
                        fill="#fff"
                        d="M643.092 332.285h4.043l.568-1.853h-4.043z"
                        data-name="Path 21043"
                        opacity="0.5"
                        transform="translate(-562.591 -280.546)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-14)"
                        d="M650.315 350.5c2.739-1.635 5.359-3.273 7.619-4.862l2.628-2.4s3.13-10.262 3.9-11.155 7.773-3.6 7.773-3.6l-1.084 3.609c1.223-.526 2.923-1.5 3.031-1.551.317-.159 2.1 1.357.083 2.795a46.135 46.135 0 01-4.908 2.909s-2.186 8.9-3.462 11.4c-.565 1.108-7.389 9.3-15.586 17.187z"
                        data-name="Path 21044"
                        transform="translate(-598.488 -280.859)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-15)"
                        d="M611.558 479.39a6.117 6.117 0 007.854-3.091 5.6 5.6 0 00.31-.959l.483-2.054 13.967-59.775.587-2.561a8.841 8.841 0 00-5.534-10.212 9.544 9.544 0 00-12.254 4.823 8.673 8.673 0 00-.643 2.355l-.311 2.4h-.007l-7.81 60.825-.283 2.253a5.718 5.718 0 003.641 5.996z"
                        data-name="Path 21045"
                        transform="translate(-508.991 -269.346)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-16)"
                        d="M612.911 461.35l.58-3.638 8.035 2.268.737 2.928 2.284-2.073 7.172 2.027.15 15.3a3.4 3.4 0 001.6 2.831l6.5 4.14a1.775 1.775 0 01.768 2.027l-.008.029a1.906 1.906 0 01-2.085 1.284l-13.7-1.826a3.624 3.624 0 01-2.514-1.517l-5.529-8.035-2.65 7.147-3.875-1.095-1.551-15.647z"
                        data-name="Path 21046"
                        transform="translate(-515.933 -260.085)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-17)"
                        d="M637.169 475.266l-2.65 7.146-3.875-1.094-1.218-12.232z"
                        data-name="Path 21047"
                        transform="translate(-536.196 -258.256)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-18)"
                        d="M605.434 455.507l6.018-50.654.145-1.057.2-1.514a10.52 10.52 0 01.776-2.841 11.218 11.218 0 0110.362-6.612 11.515 11.515 0 014.28.819 10.638 10.638 0 016.643 12.3l-.629 2.739s-9.587 41.47-11.586 49.093-16.749 2.281-16.209-2.273z"
                        data-name="Path 21048"
                        transform="translate(-505.29 -270.513)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-19)"
                        d="M684.739 311.688a9.051 9.051 0 00-11.594 4.7 8.141 8.141 0 00-.645 3.379l.574 23.858v.1a8.523 8.523 0 005.566 7.692 9.051 9.051 0 0011.594-4.7 8.143 8.143 0 00.645-3.384l-.574-23.858v-.1a8.528 8.528 0 00-5.566-7.69z"
                        data-name="Path 21049"
                        transform="translate(-639.911 -283.65)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-20)"
                        d="M685.682 328.139l-.3.068c-8.136 1.855-16.306-2.943-18.247-10.715l-2.286-9.153c-1.942-7.773 3.079-15.577 11.215-17.432l.3-.068c8.136-1.855 16.3 2.943 18.247 10.716l2.286 9.153c1.944 7.771-3.077 15.577-11.215 17.431z"
                        data-name="Path 21050"
                        transform="translate(-636.978 -286.974)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-21)"
                        d="M693.547 334.548l-23.085-.988.389-5.678a2.136 2.136 0 012.523-1.895l15.748 2.8a5.271 5.271 0 014.43 5.707z"
                        data-name="Path 21051"
                        transform="translate(-640.239 -281.263)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-22)"
                        d="M604.622 484.423a5.883 5.883 0 006.007-5.738 5.512 5.512 0 00-.1-1l-.381-2.074h.006l-11.16-60.311-.489-2.58a9.41 9.41 0 00-18.591 1.559 8.612 8.612 0 00.355 2.41l.681 2.335h-.008l17.259 59.02.644 2.178a5.983 5.983 0 005.777 4.201z"
                        data-name="Path 21052"
                        transform="translate(-452.181 -268.505)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-23)"
                        d="M563.524 468.515l-.426-3.2 15.337-1.816 7.669 13.981c.469 1.051 2.389 1.891 3.56 2.109l7.67 1.435a1.851 1.851 0 011.525 1.588v.029a1.82 1.82 0 01-1.407 1.952l-13.38 3.354a3.746 3.746 0 01-2.928-.478l-8.335-5.385.429 7.569-4.017.413-7.734-13.866z"
                        data-name="Path 21053"
                        transform="translate(-419.035 -259.153)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-24)"
                        d="M593.2 479.909l.43 7.57-4.017.413-6.046-10.845z"
                        data-name="Path 21054"
                        transform="translate(-439.432 -256.976)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-25)"
                        d="M581.72 402.857v-.357a16.445 16.445 0 0118.01-16.369l84.418 8.085.21.035c.3.036.593.075.883.134l.2.045c.272.058.543.122.813.2l.233.075c.246.075.49.158.735.252l.27.109c.213.089.426.181.65.29l.311.159a11.157 11.157 0 01.903.525c.15.1.3.2.444.3s.272.2.4.3.234.187.355.289c.15.124.291.251.426.375l.26.257c.157.15.308.312.451.468l.173.2c.16.189.317.381.466.579l.1.136c.16.225.315.451.5.751a10.526 10.526 0 011.34 3.516l.239 1.266c1.467 7.937 3.258 17.7 5.027 27.333 1.727 9.4 4.27 23.995 5.236 29.231s-14.588 8.324-15.966 4.147-14.521-48.74-14.521-48.74l-75.5 2.841a16.445 16.445 0 01-17.06-16.431z"
                        data-name="Path 21055"
                        transform="translate(-548.461 -271.602)"
                    ></path>
                    <path
                        fill="#172b36"
                        d="M656.171 415.452c3.145 7.936 51.717-13.04 47.376-18.247-5.8-6.95-10.067-11.914-10.067-11.914s3.033-42.769.48-45.789c-3.985-4.717-16.8-6.669-30.336-8.086-11.3-1.182-15.716 3.36-16.866 5.964-2.795 6.324 11.309 57.488 11.309 57.488s-3.657 16.142-1.896 20.584z"
                        data-name="Path 21056"
                        transform="translate(-622.533 -280.416)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-26)"
                        d="M613.377 373.488c3.46-2.577 18.472-14.886 24.062-20.049 1.678-1.9 2.71-3.194 2.759-3.262.211-.291-.231-3.315-.068-4.119s.249-4.13.364-4.52 3.293-4.65 3.647-5.061 1.792-.408 1.374 1.185-1.474 3.441-.9 3.494 2.478-1.614 2.675-1.871.193-6.913.209-7.539 1.652-1.877 1.987-.594 1.055 7.384 1.055 7.384 2.178 4 1.861 4.78l-.217.526a2.863 2.863 0 01.517 1.118 4.252 4.252 0 01-.485 1.328 14.859 14.859 0 01.028 1.8 58.064 58.064 0 01-6.635 8.094c-.053.045-22.546 38.54-33.163 31.636-6.008-3.91-2.947-11.446.93-14.33z"
                        data-name="Path 21057"
                        transform="translate(-528.097 -280.518)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-21)"
                        d="M620.9 357.664s.8-.513 3.345-2.577 10.431 6.1 8.132 9.1c-1.727 2.26-4.087 5.127-4.087 5.127z"
                        data-name="Path 21058"
                        transform="translate(-521.93 -276.634)"
                    ></path>
                    <path
                        fill="#172b36"
                        d="M623.411 344.522a9.32 9.32 0 019.525-9.1 9.581 9.581 0 018.438 4.887l15.614 28.534c3.354-2.547 8.8-6.736 10.129-7.709 3.086-2.253 11.743 7.182 9.141 10.516-1.68 2.153-8.024 9.569-10.443 11.827a25.389 25.389 0 01-6.571 4.689 7.735 7.735 0 01-3.582.87h-.017c-.025 0-.047-.006-.071-.006a7.571 7.571 0 01-6.212-3.285c-8.11-12.047-14.132-21-24.435-36.3h.006a8.758 8.758 0 01-1.521-4.917z"
                        data-name="Path 21059"
                        transform="translate(-568.787 -279.742)"
                    ></path>
                    <path
                        fill="#f7ede2"
                        d="M614.038 348.458h45.714a5.348 5.348 0 015.1 3.744l22.257 70.94h37.554a3.358 3.358 0 013.358 3.358v.068a3.358 3.358 0 01-3.358 3.358h-92.347l-23.38-74.521a5.346 5.346 0 015.1-6.947z"
                        data-name="Path 21060"
                        transform="translate(-602.972 -277.647)"
                    ></path>
                    <path
                        fill="#fff5dc"
                        d="M0 0H8.288V57.382H0z"
                        data-name="Rectangle 3199"
                        transform="translate(58.497 152.279)"
                    ></path>
                    <path
                        fill="#f0daa5"
                        d="M0 0H12.385V9.118H0z"
                        data-name="Rectangle 3200"
                        transform="translate(56.385 152.279)"
                    ></path>
                    <path
                        fill="#f7ede2"
                        d="M0 0H9.401V30.802H0z"
                        data-name="Rectangle 3201"
                        transform="translate(57.877 182.814)"
                    ></path>
                    <g
                        data-name="Group 14338"
                        transform="translate(92.69 217.118)"
                    >
                        <path
                            fill="#172b36"
                            d="M637.99 484.57a5.156 5.156 0 11-5.156-5.155 5.156 5.156 0 015.156 5.155z"
                            data-name="Path 21061"
                            transform="translate(-627.677 -473.714)"
                        ></path>
                        <path
                            fill="#fff"
                            d="M635.187 484.229a2.693 2.693 0 11-2.693-2.693 2.694 2.694 0 012.693 2.693z"
                            data-name="Path 21062"
                            transform="translate(-627.337 -473.374)"
                        ></path>
                        <path
                            fill="#172b36"
                            d="M0 0H1.828V6.639H0z"
                            data-name="Rectangle 3202"
                            transform="translate(6.099)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14339"
                        transform="translate(22.28 217.118)"
                    >
                        <path
                            fill="#172b36"
                            d="M688.34 484.57a5.156 5.156 0 105.156-5.155 5.156 5.156 0 00-5.156 5.155z"
                            data-name="Path 21063"
                            transform="translate(-688.34 -473.714)"
                        ></path>
                        <path
                            fill="#fff"
                            d="M690.461 484.229a2.693 2.693 0 102.694-2.693 2.693 2.693 0 00-2.694 2.693z"
                            data-name="Path 21064"
                            transform="translate(-687.999 -473.374)"
                        ></path>
                        <path
                            fill="#172b36"
                            d="M0 0H1.828V6.639H0z"
                            data-name="Rectangle 3203"
                            transform="translate(2.385)"
                        ></path>
                    </g>
                    <g
                        data-name="Group 14340"
                        transform="translate(59.061 213.615)"
                    >
                        <circle
                            cx="5.156"
                            cy="5.156"
                            r="5.156"
                            fill="#172b36"
                            data-name="Ellipse 1679"
                            transform="translate(0 5.7)"
                        ></circle>
                        <path
                            fill="#fff"
                            d="M658.773 481.206a2.693 2.693 0 102.693-2.694 2.694 2.694 0 00-2.693 2.694z"
                            data-name="Path 21065"
                            transform="translate(-656.311 -470.354)"
                        ></path>
                        <path
                            fill="#172b36"
                            d="M0 0H1.828V6.639H0z"
                            data-name="Rectangle 3204"
                            transform="translate(2.385)"
                        ></path>
                    </g>
                    <path
                        fill="#f7ede2"
                        d="M663.01 479.858l-33.277-7.458v-6.342l33.277 9.4z"
                        data-name="Path 21066"
                        transform="translate(-562.391 -258.745)"
                    ></path>
                    <path
                        fill="#f7ede2"
                        d="M666.556 479.858l33.277-7.465v-6.342l-33.277 9.4z"
                        data-name="Path 21067"
                        transform="translate(-641.956 -258.744)"
                    ></path>
                    <path
                        fill="#f0daa5"
                        d="M721.181 413.243l-.381-1.213h-56.917l.381 1.213zm-6.246-19.909l.381 1.213H658.4l-.381-1.213zm2.929 9.349l.381 1.212h-56.918l-.381-1.212zm-11.731-37.39l.381 1.212H649.6l-.381-1.212zm-2.928-9.349l.381 1.213h-56.918l-.381-1.213zm5.865 18.7l.381 1.213h-56.922l-.381-1.213zm2.93 9.344l.38 1.213h-56.915l-.383-1.213z"
                        data-name="Path 21068"
                        transform="translate(-639.773 -276.443)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-28)"
                        d="M692.582 310.723c.066 0 .13.009.2.009a167.444 167.444 0 01-.336-3.3c-.9-9.987 3.9-14.95 10.356-16.745l1.467-.408.279-.075 5.394-1.5a10.012 10.012 0 012.125-.87 9.864 9.864 0 0112.074 6.224l-6.344 1.763c1.335 3.186 1.335 9.969-3.311 12.765a3.428 3.428 0 00-1.952 6.565c-.526 1.276-6.574 44-19.951 44a24.219 24.219 0 110-48.438z"
                        data-name="Path 21069"
                        transform="translate(-668.36 -287.44)"
                    ></path>
                </g>
                <g
                    data-name="Group 19721"
                    transform="translate(879.137 688.682)"
                >
                    <path
                        fill="url(#linear-gradient-29)"
                        d="M2765.938 3163.3s1.042 5.9 1.552 11.732a4.021 4.021 0 01-3.847 4.357c-7.772.324-24.4.954-30.363.687a1.309 1.309 0 01-1.152-1.792 2.931 2.931 0 011.5-1.578l27.461-12.622z"
                        data-name="Path 27071"
                        transform="translate(-2674.341 -2880.732)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-30)"
                        d="M2876.633 3140.466l-.837 7.052s-4.811 2.239-8.1-.282l1.992-7.859z"
                        data-name="Path 27072"
                        transform="translate(-2784.182 -2861.366)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-31)"
                        d="M2878.463 2448.542c.362 1.842 1.94 91.9-7.687 132.261a1.37 1.37 0 00.8 1.593 23.869 23.869 0 007.841 1.594 2.36 2.36 0 002.34-1.694c2.752-9.364 15.938-56.28 17.658-101.1 1.922-50.079 0-1.705 0-1.705l-1.127-30.956z"
                        data-name="Path 27073"
                        transform="translate(-2786.641 -2302.026)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-32)"
                        d="M3029.241 3179.456s1.658 2.976 3.262 10.886a2.453 2.453 0 01-2.158 2.938c-2.566.253-6.517.577-9.682.555a2.446 2.446 0 01-2.421-2.728 20.628 20.628 0 014.817-11.315z"
                        data-name="Path 27074"
                        transform="translate(-2906.054 -2893.816)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-33)"
                        d="M3050.949 3130.039l-1.956 10.665s-1.933 1.438-5.757.4l.933-11.475z"
                        data-name="Path 27075"
                        transform="translate(-2926.306 -2853.47)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-34)"
                        d="M2946.04 2441.876c7.874 47.517-1.708 117.441-4.939 131.127a2.135 2.135 0 01-1.723 1.621 14.324 14.324 0 01-6.158-.246 1.956 1.956 0 01-1.407-1.8c-.433-11.065.347-78.991-6.989-105.835a6.1 6.1 0 00-2.352-3.364l-14.239-10.123-1.092-16.362z"
                        data-name="Path 27076"
                        transform="translate(-2816.115 -2292.596)"
                    ></path>
                    <g
                        data-name="Group 19717"
                        transform="translate(10.059 22.703)"
                    >
                        <path
                            fill="#f4ca47"
                            d="M2703.049 1973.664c23.6-.39 49.63 6.2 49.63 6.2 7.033 41.039 1.23 88.374 1.23 88.374-18.266 4.79-38.872-2.929-38.872-2.929-3.037-17.153-17.2-65.427-17.2-65.427a77.732 77.732 0 01-28.846-9.155c5.966-6.915 8.778-15.714 10-20.915a79.173 79.173 0 0024.058 3.852z"
                            data-name="Path 27077"
                            transform="translate(-2633.353 -1937.122)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-35)"
                            d="M2481.778 1799.879a4.514 4.514 0 015.251-1.475c3.472 14.667 21.678 26.559 40.383 32.372-1.222 5.2-4.034 14-10 20.915-31.041-17.452-35.634-51.812-35.634-51.812z"
                            data-name="Path 27078"
                            transform="translate(-2481.778 -1798.091)"
                        ></path>
                    </g>
                    <g data-name="Group 19718">
                        <path
                            fill="url(#linear-gradient-36)"
                            d="M2439.552 1704.024c-.243.771 2.566 1.945 4.22-1.209.058-1.511.977-7.986.073-9.217-4.81-6.557-14.135-14.777-14.135-14.777a2.219 2.219 0 00-.278 3.083l4.524 4.713c-.946 1.71-2.1 3.153-2.641 5.221a6.335 6.335 0 00.632 4.665c.794 1.43 1.877 3.29 2.9 4.745a6.259 6.259 0 004.705 2.776z"
                            data-name="Path 27079"
                            transform="translate(-2428.931 -1678.821)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-37)"
                            d="M2461.855 1735.837l4.122 3.495 1.294 3.479-3.675 4.077s-1.236-6.9-2.154-8.4-.442-2.214.413-2.651z"
                            data-name="Path 27080"
                            transform="translate(-2454.849 -1724.984)"
                        ></path>
                    </g>
                    <path
                        fill="#f4ca47"
                        d="M3061.119 2045.466l9.051-22.847s22.039 14.975 25.663 33.961a52.153 52.153 0 00-19.159 5.135c-1.63-8.965-15.555-16.249-15.555-16.249z"
                        data-name="Path 27081"
                        transform="translate(-2940.785 -1957.179)"
                    ></path>
                    <path
                        fill="url(#linear-gradient-38)"
                        d="M2946.006 2211.42s24.745 13.984 33.094 2.043a9.385 9.385 0 001.721-7.293 52.142 52.142 0 0119.159-5.135 26.206 26.206 0 01-2.848 17.963c-14.625 26.753-54.4-1.478-54.4-1.478z"
                        data-name="Path 27082"
                        transform="translate(-2844.932 -2101.635)"
                    ></path>
                    <g
                        data-name="Group 19719"
                        transform="translate(42.232 71.817)"
                    >
                        <path
                            fill="url(#linear-gradient-39)"
                            d="M2712.615 2131.264l-13.5-47.161a1.376 1.376 0 00-1.164-.991 591.653 591.653 0 00-17.722-1.779c-9.86-.834-21.311-1.51-28.154-.935a1.38 1.38 0 00-1.128 1.986c3.891 7.872 19.9 39.895 27.912 50.385a1.384 1.384 0 001.113.547l31.333-.292a1.38 1.38 0 001.31-1.76z"
                            data-name="Path 27083"
                            transform="translate(-2650.799 -2075.604)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-40)"
                            d="M2752.9 2057.759l-9.791-1.631a.7.7 0 00-.783.913l.969 2.937a.7.7 0 00.574.476l9.421 1.259a.7.7 0 00.778-.857l-.6-2.564a.7.7 0 00-.568-.533z"
                            data-name="Path 27084"
                            transform="translate(-2724.875 -2056.118)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-41)"
                            d="M2740.808 2078.527l-.807-4.2a.971.971 0 00-.856-.783l-19.775-2.007a.971.971 0 00-1.043 1.193l1.257 5.236a.972.972 0 00.9.743l19.325.973a.971.971 0 00.999-1.155z"
                            data-name="Path 27085"
                            transform="translate(-2705.452 -2068.597)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-42)"
                            d="M2887.426 2220.28s1.882-7.735 1.122-10.48-2.229-6.272-7.13-5.489a71.785 71.785 0 00-10.682 2.849s.292 3.575 4.017 2.421l-2.31 1.787s.33 2.863 3.476 1.333l-2.148 2.37a2.5 2.5 0 003.666.843c2.316-1.507 3.425 5.297 9.989 4.366z"
                            data-name="Path 27086"
                            transform="translate(-2828.873 -2176.013)"
                        ></path>
                    </g>
                    <path
                        fill="url(#linear-gradient-43)"
                        d="M2908.491 1889.436l7.508 21.711a.781.781 0 01-.356.942c-2.047 1.131-9.42 4.4-18.3-1.231a3.681 3.681 0 01-1.7-2.631l-2.432-18.791z"
                        data-name="Path 27087"
                        transform="translate(-2804.836 -1849.347)"
                    ></path>
                    <g
                        data-name="Group 19720"
                        transform="translate(77.222 6.706)"
                    >
                        <path
                            fill="url(#linear-gradient-44)"
                            d="M2854.219 1765.793c-.226.314-7.282 24.905-7.282 24.905l14.127 3.73 10.15-22.314s-13.214-11.578-16.995-6.321z"
                            data-name="Path 27088"
                            transform="translate(-2844.593 -1754.835)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-45)"
                            d="M2855.633 1732.981a2.1 2.1 0 01.2 1.839l-4.359 11.989a2.029 2.029 0 01-.268.508c-.628.863-2.85 3.448-5.426 1.015-2.2-2.074-4.244-3.95-6.157-4.516a2.1 2.1 0 00-2.6 1.454 78.319 78.319 0 00-2.38 11.314 2.089 2.089 0 001.682 2.283 58.2 58.2 0 0013.072 1.21 2.1 2.1 0 001.788-1.206c1.449-3.087 5.546-11.721 7.077-13.981 1.423-2.1 4.807-2.068 6.355-1.931a2.11 2.11 0 001.6-.535c1.315-1.185 3.479-3.921.37-7.24a2.1 2.1 0 01.094-2.925c1.45-1.415 2.855-3.816.493-6.806a6.547 6.547 0 00-7.621-2.057 2.178 2.178 0 01-2.487-.812 1.389 1.389 0 00-1.226-.632 2.037 2.037 0 01-2.074-2.035 5.375 5.375 0 00-4.119-5.475c-6.168-1.984-10.081 3.957-10.505 8.412s6.386 6.226 10.4 5.963c2.948-.192 5.137 2.644 6.091 4.164z"
                            data-name="Path 27089"
                            transform="translate(-2834.622 -1714.05)"
                        ></path>
                        <path
                            fill="url(#linear-gradient-46)"
                            d="M2950.086 1864.753s1.072-7.732 4.3-8.081 6.334 2.327 3.091 6.642c-4.037 5.367-7.391 1.439-7.391 1.439z"
                            data-name="Path 27090"
                            transform="translate(-2928.108 -1829.499)"
                        ></path>
                    </g>
                </g>
                <ellipse
                    cx="91.5"
                    cy="3.5"
                    fill="#0f3981"
                    data-name="Ellipse 36"
                    opacity="0.47"
                    rx="91.5"
                    ry="3.5"
                    transform="translate(782.46 984.539)"
                ></ellipse>
                <ellipse
                    cx="91.5"
                    cy="3.5"
                    fill="#0f3981"
                    data-name="Ellipse 37"
                    opacity="0.47"
                    rx="91.5"
                    ry="3.5"
                    transform="translate(458.46 984.539)"
                ></ellipse>
            </g>
        </Icon>
    );
};

export default MainIllustration;
