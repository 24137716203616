import React from "react";

import {
    Flex,
    Spacer,
    CloseButton,
    Heading as ChakraHeading,
} from "@chakra-ui/react";

/**
 * @description Renders heading with a close button
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {function} props.onRemove
 * @returns {JSX.Element}
 */
const Heading = ({ children, onRemove }) => (
    <Flex>
        <ChakraHeading fontSize={"xl"} fontWeight={"semibold"}>
            {children}
        </ChakraHeading>
        <Spacer />
        {onRemove && <CloseButton onClick={onRemove} />}
    </Flex>
);

export default Heading;
