import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const PaybackOnInvestment = ({ ...rest }) => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 46 45"
            aria-labelledby={"paybackOnInvestmentTitle"}
            {...rest}
        >
            <title id={"paybackOnInvestmentTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Business process automation",
                })}
            </title>
            <desc id={"paybackOnInvestmentDesc"}>
                {intl.formatMessage({
                    defaultMessage:
                        "A graphic showing objects balancing on cone.",
                })}
            </desc>
            <g
                data-name="[icon]-payback-investment"
                transform="translate(.187 -.243)"
            >
                <g
                    fill="none"
                    stroke="#459bec"
                    strokeWidth="1"
                    data-name="Ellipse 30"
                    transform="translate(-.187 1.243)"
                >
                    <ellipse
                        cx="22"
                        cy="21"
                        stroke="none"
                        rx="22"
                        ry="21"
                    ></ellipse>
                    <ellipse cx="22" cy="21" rx="21.5" ry="20.5"></ellipse>
                </g>
                <g data-name="Group 19676">
                    <rect
                        width="46"
                        height="45"
                        fill="#fff8eb"
                        data-name="Rectangle 3380"
                        rx="14"
                        transform="translate(-.187 .243)"
                    ></rect>
                    <g fill="#f5d689" transform="translate(9.538 12.987)">
                        <path
                            d="M26.422 286H.8a.8.8 0 000 1.595h11.4l-4.06 6.766a.8.8 0 00.684 1.208h9.569a.8.8 0 00.684-1.208l-4.06-6.766h11.4a.8.8 0 000-1.595z"
                            data-name="Path 70"
                            transform="translate(0 -274.596)"
                        ></path>
                        <path
                            d="M109.989 101.22a4.873 4.873 0 104.875 4.873 4.879 4.879 0 00-4.875-4.873zm1.5 6.313l-1.918-1.107v-2.659h.843v2.172l1.5.864z"
                            data-name="Path 71"
                            transform="translate(-104.959 -101.22)"
                        ></path>
                    </g>
                </g>
                <g transform="translate(24.057 14.298)">
                    <g fill="#f5d689" data-name="Group 13">
                        <ellipse
                            cx="4.729"
                            cy="1.225"
                            data-name="Ellipse 3"
                            rx="4.729"
                            ry="1.225"
                            transform="translate(.119)"
                        ></ellipse>
                        <path
                            d="M6.582 111.755a11.9 11.9 0 003.351.431 9.774 9.774 0 003.938-.65c.51-.216.8-.456.8-.674v-.993a.224.224 0 000-.035 1.916 1.916 0 01-.954.623v.63a.157.157 0 01-.314 0v-.525a7.715 7.715 0 01-.251.07v.765a.21.21 0 01-.419 0v-.674a15.451 15.451 0 01-2.8.236c-2.485 0-4.524-.494-4.736-1.123a.219.219 0 000 .035v1.015c-.003.288.516.614 1.385.869z"
                            data-name="Path 28"
                            transform="translate(-5.085 -108.003)"
                        ></path>
                        <path
                            d="M57.354 238.917a1.916 1.916 0 01-.954.623v.63a.157.157 0 01-.314 0v-.525a7.715 7.715 0 01-.251.07v.765a.21.21 0 01-.419 0v-.68a15.452 15.452 0 01-2.8.236c-2.485 0-4.524-.494-4.736-1.123a.218.218 0 000 .035v1.015c0 .29.519.616 1.388.871a11.9 11.9 0 003.351.431 9.774 9.774 0 003.938-.65c.51-.216.8-.456.8-.674v-.993a.212.212 0 00-.003-.031z"
                            data-name="Path 29"
                            transform="translate(-46.876 -234.381)"
                        ></path>
                        <path
                            d="M4.739 366.708a15.453 15.453 0 01-2.8-.236v.674a.21.21 0 11-.419 0v-.765a5.098 5.098 0 01-.251-.07v.525a.157.157 0 01-.314 0v-.63a1.916 1.916 0 01-.955-.621.22.22 0 000 .035v.993c0 .218.291.458.8.674a9.775 9.775 0 003.938.65 11.9 11.9 0 003.351-.431c.869-.255 1.388-.581 1.388-.871v-1.015a.22.22 0 000-.035c-.214.629-2.253 1.123-4.738 1.123z"
                            data-name="Path 30"
                            transform="translate(0 -358.395)"
                        ></path>
                        <path
                            d="M52.924 180.854a11.152 11.152 0 01-3.351-.431 5.632 5.632 0 01-.628-.222c.093.655 2.173 1.179 4.725 1.179 2.612 0 4.729-.548 4.729-1.225 0-.242-.27-.467-.737-.657v.031c0 .229-.321.482-.88.706a11.152 11.152 0 01-3.858.619z"
                            data-name="Path 31"
                            transform="translate(-47.92 -176.208)"
                        ></path>
                        <path
                            d="M5.792 308.154c2.553 0 4.633-.524 4.725-1.179a5.635 5.635 0 01-.628.222 11.151 11.151 0 01-3.351.431 11.151 11.151 0 01-3.859-.617c-.559-.224-.88-.477-.88-.706v-.031c-.466.19-.737.415-.737.657.001.674 2.118 1.223 4.73 1.223z"
                            data-name="Path 32"
                            transform="translate(-1.041 -300.325)"
                        ></path>
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default PaybackOnInvestment;
