import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import { Icon } from "@chakra-ui/react";

const QualityImprovement = () => {
    const intl = useIntl();

    return (
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="5em"
            height="5em"
            viewBox="0 0 81 81"
            aria-labelledby={"qualityImprovementTitle"}
        >
            <title id={"qualityImprovementTitle"}>
                {intl.formatMessage({
                    defaultMessage: "Job automation calculator",
                })}
            </title>
            <desc id={"qualityImprovementDesc"}>
                {intl.formatMessage({
                    defaultMessage: "A graphic showing exclamation point.",
                })}
            </desc>
            <g
                data-name="[icon]-quality-improvement"
                transform="translate(-890 -4250)"
            >
                <rect
                    width="81"
                    height="81"
                    fill="#dbebf9"
                    data-name="Rectangle 3452"
                    rx="25"
                    transform="translate(890 4250)"
                ></rect>
                <path
                    fill="#1277d6"
                    d="M18 3a15 15 0 1015 15A15.005 15.005 0 0018 3zm1.5 22.5h-3v-3h3zm0-6h-3v-9h3z"
                    data-name="Icon material-error"
                    transform="translate(913 4273)"
                ></path>
            </g>
        </Icon>
    );
};

export default QualityImprovement;
